//module
@use "../settings/vars" as v;
@use "../settings/mixins" as m;

a{text-decoration: none;color:v.$BLUE;
	img{
		transition: opacity v.$HOVER;
	}
	@include m.hover{text-decoration: underline;
		img{opacity:0.7;}
	}

}

.tbdisp{display: none;}
.spdisp{display: none;}

@include m.for-tb(){
	.tbdisp{display: block;}
	.tbhide{display: none;}
}

@include m.for-sp(){
	.spdisp{display: block;}
	.sphide{display: none;}
}


// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl-left {	float: left;}
.fl-right {	float: right;}
.cl{clear:both;}

.btn{
	display: flex; justify-content: space-between; align-items: center; gap:20px;
	width: min-content; height: 64px; padding: 0 25px 0 30px;
	font-size: 1.8rem; font-weight: 700; color: #fff; line-height: 1.2;
	border: none; border-radius: 32px;
	background: v.$DGREEN;
	position: relative;
	overflow: hidden;
	@include m.for-sp(){
		max-width: 100%;
	}
	&:after{
		content:"";
		width:100%; height: 100%;
		background: linear-gradient(90deg,v.$GREEN,v.$DGREEN);
		position: absolute; left:-100%; top:0;
		transition: left 0.25s v.$ease2;
	}
	.label{
		width: max-content;
		position: relative; z-index: 2;		
	}
	.icon{
		display: flex; justify-content: center; align-items: center; flex-shrink: 0;
		width:31px; height: 31px; 
		background: #D7E0E0;
		border-radius: 50%;
		position: relative; z-index: 2;
		svg{
			fill:v.$DGREEN;
		}
	}
	@include m.hover{
		text-decoration: none;
		&:after{
			left:0;
		}
	}
	&.btn-submit{
		min-width:240px;
	}
	&.btn-back{
		justify-content: center;
		min-width:100px;height: 48px; padding:0 20px;
		font-size:1.6rem;
		background: v.$GRAY;
		border-radius: 24px;
		transition: opacity v.$HOVER;
		&:after{
			display: none;
		}
		@include m.hover{
			opacity: 0.7;
		}
	}
	@include m.for-sp(){
		height: 56px; padding: 0 20px 0 25px; gap: 14px;
		font-size:1.6rem; 
	}
}

.btn-area{
	display: flex;justify-content: center; align-items: center; gap: 30px;
	margin-top: 40px;
	@include m.for-sp(){
		flex-direction: column; gap:20px;
	}
}
form .btn-area{
	@include m.for-sp(){
		flex-direction: column-reverse;
	}
}


//フォントサイズ
.l{font-size:1.8rem;display: inline-block;}
.s{font-size:1.4rem;display: inline-block;}
.xs{font-size:1.2rem;display: inline-block;}

@include m.for-sp(){
	.xs{font-size: 1.0rem;}
	.s{font-size: 1.2rem;}
	.l{font-size: 1.6rem;}
}


//フォントカラー
.red{	color: v.$RED;}
.green{color: v.$GREEN;}



.page-tit-wrap{
	height:200px;
	background: v.$DGREEN;
	display: flex; align-items: center;
	overflow: hidden;
	position: relative;
	&:after{
		content:"";
		width:100%; height:30px;
		background: linear-gradient(0deg,rgba(#000,0.1),rgba(#000,0));
		position: absolute;bottom:0;left:0; z-index: 2;
	}
	@include m.for-sp(){
		height: 120px;
	}
	.page-tit-box{
		width:960px; margin: 0 auto;
		display: flex; justify-content: space-between; align-items: center;
		@include m.for-tb(){
			width:100%; padding-left: 30px;
		}
	}
	.page-tit{
		font-size:4rem; color: #fff; font-weight: 700; letter-spacing: 0.15em;
		text-shadow: rgba(#000,0.15) 0 0 10px;
		.s{
			font-size:3rem;
		}
		@include m.for-tb(){
			font-size:3.4rem;
			position: relative; z-index: 2;
			.s{
				font-size:2.2rem;
			}
		}
		@include m.for-sp(){
			font-size: 2.4rem;
			.s{
				font-size: 1.8rem;
			}
		}
	}
	.page-tit-image{
		position: relative; right:-100px; z-index:1;
		@include m.for-tb(){
			position: absolute; bottom:0;
		}
		@include m.for-sp(){
			width:200px;
		}
	}
}
.page-body{
	width: 960px;margin: 0 auto; padding: 60px 0 90px;
	@include m.for-tb(){
		width:100%; padding: 60px 50px 90px;
	}
	@include m.for-sp(){
		width:100%; padding: 30px 30px 50px;
	}
	a:not([class]){
		text-decoration: underline; color: v.$DGREEN;
		@include m.hover{
			text-decoration: none;
		}
	}
	>*:first-child{
		margin-top: 0 !important;;
	}
	p,ol,ul,dl,table{
		margin-top: 30px;
		
		@include m.for-sp(){
			margin-top: 24px;
		}
	}
	h2,h3,h4,h5,h6{line-height: 1.2; margin-top: 30px;
		&:first-child{
			margin-top: 0 !important;
		}
	}
	h2{
		font-size: 3.2rem; margin-top: 50px;
		@include m.for-sp(){
			font-size: 2.4rem; margin-top: 30px;
		}

	}
	h3{
		font-size: 2.4rem;
		@include m.for-sp(){
			font-size:2rem;
		}
	}
	h4{
		font-size: 2.1rem;
		@include m.for-sp(){
			font-size:1.8rem;
		}
	}
	h5{
		font-size: 1.8rem;
		@include m.for-sp(){
			font-size:1.6rem;
		}
	}
	h6{
	
	}
	
	ul:not([class]){
		li{
			padding-left: 15px;position: relative;margin-top: 0.5em;
			line-height:1.5;
			&:before{
				content:"";width: 6px;height: 6px;display: block;background: v.$GREEN;
				border-radius: 1px;
				position: absolute;left:2px;top:calc(0.9em - 3px);
			}
		}
	}
	ol:not([class]){
		list-style-type: decimal;
		line-height: 1.5;
		li{
			margin-top: 0.5em;
		}
	}
	
	img{max-width: 100%;height: auto;}
}

.box{
	padding:24px; margin-top: 30px;
	border: 1px solid v.$DGREEN; border-radius: 10px;
	& > *:first-child{
		margin-top: 0 !important;
	}
	h2,h3,h4,h5,h6{
		font-size:2rem;
	}
	> * + *{margin-top:15px !important;}
}

blockquote{
	@extend .box;
	background: v.$BGC_GRAY;
	position: relative;
	&:before{
		content:"“";
		position: absolute; left:10px; top:-40px;
		font-size:10rem; color: v.$DGREEN; line-height: 1; font-weight: 700;
	}
	&:after{
		content:"”";
		position: absolute; right:10px; bottom:-60px;
		font-size:10rem; color: v.$DGREEN; line-height: 1; font-weight: 700;
	}
}

.block{
	padding: 2em 0;
	&:first-child{padding-top: 0;}
	>* + *{margin-top:1.5em;}
}


//パンくずリスト
.breadcrumb{
	display: flex; align-items: center;
	width: 1020px; height: 40px; padding:0 30px; margin:0 auto;
	font-size: 1.2rem;
	background: v.$BGC_GREEN;
	border-radius:10px;
	position: relative; top:-20px; z-index: 2;
	@include m.for-tb(){
		width: 100%;
		border-radius: 0;
		top:0;
	}
	@include m.for-sp(){
		font-size:1rem; height: 30px;
	}

	li{
		display: inline-block;
		
		&:before{
			content:"›";display: inline-block;margin: 0 5px 0 2px;
			color: v.$FONT_COLOR;
		}
		&:first-child:before{display: none;}
		a{
			color: v.$DGREEN;
		}
		&.current{
			color: v.$BLACK; font-weight: 700;
		}
		@include m.for-sp(){
			&:nth-child(n+3){
				display: none;
			}
		}
	}
}

//表組み
table{
	width: 100%;font-size: v.$FONT_SIZE;line-height: 1.7;
	border-collapse: collapse;
	background: #fff;
	th,td{
		padding:12px 10px;
	}
	tr{
		border-bottom: 1px solid v.$BDC;
	}
	thead{
		background: v.$GREEN;
		th{
			font-weight: bold; text-align: center; color: #fff;
		}
	}
	tbody{
		th{
			font-weight: bold; text-align: left;
			background: v.$BGC_GREEN;
			width:25%;
		}
	}
}

@include m.for-sp(){
	table{
		font-size: 1.4rem;
		&:not(.nobreak){
			width:calc(100% + 20px);
			position: relative; left:-10px;
			tr,th,td{display: block;border: none;padding: 0;width: 100%;}
			thead{
				display: none;
			}
			tbody{
				tr{border-bottom: 1px solid v.$BDC;}
				th{
					padding:5px 10px;
				}
				td{
					padding:10px 10px;
				}
				
			}
		}
	}
}


//罫線
hr{	border: none;border-bottom: 1px solid v.$BDC;padding-top:80px;margin-bottom: 80px;}


//ページネーション
.paginate{
	padding-top: 50px;margin: 0 auto;
}

.paginate-list{
	display: flex;justify-content: center;position: relative; gap: 12px;
	font-family: v.$ROBOTO;
	a{
		display: flex; align-items: center; justify-content: center;
		height: 40px; width: 40px;
		color:v.$DGREEN; text-decoration: none !important; letter-spacing: 0;
		border: 1px solid v.$DGREEN; border-radius: 10px;
		background: #fff;
		transition:all v.$HOVER;
		@include m.hover{
			background: v.$BGC_GREEN;
		}
	}
	.disabled a{
		color: v.$BDC;
		pointer-events: none;
	}
	.current{
		display: flex; align-items: center; justify-content: center;
		height: 40px; width: 40px;
		color: #fff; font-weight: bold;
		border: 1px solid v.$DGREEN; border-radius: 10px;
		background: v.$DGREEN;
	}
	
}
@include  m.for-sp(){
	.paginate{
		width: 100%;padding: 30px 3vw 0;
	}
}
@include  m.for-sp(){
	.paginate-list{
		gap:6px;
		a,.current{
			width:30px;height:30px;
			font-size: 1.3rem;
		}
	}
}
#fs-paginate{
	.paginate-list{
		.fs-current{
			a{
				color: #fff;
				border: 1px solid v.$DGREEN;
				background: v.$DGREEN;
				pointer-events: none;
			}
		}
	}
}
