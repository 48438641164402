//base
@use "../settings/vars" as v;
@use "../settings/mixins" as m;

*,:before,:after{box-sizing:border-box; word-break: break-all;}

html{
	font-size: 62.5%;
}
img{
	vertical-align: middle;
	display: inline-block;
}
ol{
	list-style-position: inside;
}

strong{	font-weight: bold;}
sup,sub{height: 0; line-height: 1; vertical-align: baseline;  _vertical-align: bottom; position: relative; font-size:1rem;}
sup{bottom: 1ex;}
sub{top: .5ex;}

h1,h2,h3,h4,h5,h6{
	font-weight: 700; color: v.$BLACK;
}

body{
	font-size:v.$FONT_SIZE;font-family:v.$FONT_FAMILY; color:v.$FONT_COLOR;line-height: v.$LINE_HEIGHT;letter-spacing: 0.1em;
	background: #fff;
	font-feature-settings: "palt";	-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	&.fixed{ //メニューオープン時
		width: 100%; height: 100%;
		position: fixed;
	}
}

@include m.for-sp(){
	body{
		font-size: 1.4rem;
	}
}

::selection {
	background: rgba(v.$GREEN,0.7);
	color: #fff;
}