//page
@use "../settings/vars" as v;
@use "../settings/mixins" as m;

article{
  padding-bottom: 200px;
}


#aboutus{
  table{
    tbody{
      th,td{
        vertical-align: top;
      }
      td{
        padding:24px;
        @include m.for-sp(){
          padding:10px;
        }
      }
      th{
        background: none; padding:18px 0;
        width:140px;
        @include m.for-sp(){
          width:100%; padding: 10px 0 0;
        }
      }
    }
    .table-label{
      display: inline-block;
      width:100%; padding:8px 10px;
      font-size:1.4rem; text-align: center;
      background: v.$BGC_GREEN;
      border-radius: 5px;
      @include m.for-sp(){
        width: auto; padding:5px 10px;
      }
    }
  }


}

.column-list{
  display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap:40px 20px;
  @include m.for-tb(){
    grid-template-columns: 1fr 1fr; gap:40px;
  }
  a{
    display: block;
    height:100%; padding:20px;
    color: inherit;
    background: #fff;
    border-radius:20px;
    box-shadow: rgba(#000,0.1) 0 4px 4px;
    @include m.hover{
      text-decoration: none;
    }
  }
  .column-list-image{
    width:100%; height: 160px;
    img{
      width:100%; height: 100%;
      object-fit: cover;
    }
  }
  .column-list-info{
    display: flex; justify-content: space-between; align-items: center; gap: 0 10px; flex-wrap: wrap;
    margin-top: 10px;
    
  }
  .column-list-date{
    font-size: 1.4rem; font-family: v.$ROBOTO; letter-spacing: 0;
  }
  .column-list-tag{
    display: inline-block;
    padding: 7px 10px;
    font-size: 1.3rem; line-height: 1; color: #fff;
    background: v.$DGREEN;
    &.seminar{
      background: #00A982;
    }
    &.investment{
      background: #253631;
    }
    @include m.for-sp(){
      padding:4px 5px;
      font-size:1.2rem;
    }
  }
  .column-list-tit{
    margin-top: 10px;
    line-height: 1.5;
  }

}

#am{
  &.bn-index,&.bn-investment,&.bn-performance{
    article{
      background: v.$BGC_GRAY;
    }    
  }
  article{
    //background: v.$BGC_GRAY;
    position: relative; z-index: 1;
    &:before{
      content:"Asset Management";
      font-family: v.$ROBOTO; font-size: 13rem;color: v.$DGREEN; font-weight: 700; white-space: nowrap;
      position: absolute; left:50px; top:200px; 
      opacity: 0.1;
      transform: rotate(90deg); transform-origin: left center;
      @include m.for-tb(){
        font-size:10rem;left:30px;
      }
      @include m.for-sp(){
        display: none;
      }
    }
    .page-body{
      position: relative; z-index: 2;
    }
  }
  .am-tit{
    font-size: 3.2rem;
    @include m.for-sp(){
      font-size: 2.4rem;
      text-align: center;
    }
  }
  .column-list{
    margin-top: 60px;
    grid-template-columns: 1fr 1fr 1fr; gap:30px;
    @include m.for-tb(){
      grid-template-columns: 1fr 1fr; gap:40px;
    }
    @include m.for-sp(){
      grid-template-columns: 1fr; gap:30px;
      margin-top: 30px;
    }
  }
  
  .column-list-item{
    a{
      //border: 1px solid v.$DGREEN;
      text-decoration: none; color: inherit;
    }
  }
  .column-detail-info{
    .column-detail-date{
      margin-right: 20px;
      font-family: v.$ROBOTO; font-size: 1.5rem; letter-spacing: 0;
    }
    .column-detail-tag{
      display: inline-block;
      padding: 7px 10px;
      font-size: 1.3rem; line-height: 1; color: #fff;
      background: v.$DGREEN;
      &.seminar{
        background: #00A982;
      }
      &.investment{
        background: #253631;
      }
    }
  }
  .column-detail-tit{
    margin-top: 20px; margin-bottom: 50px;
    font-size:3.2rem;
    @include m.for-sp(){
      font-size: 2.4rem; margin-top: 10px;
    }
  }
  .page-navigation-list{
    display: block;
    width:960px; margin: 0 auto 60px; padding-top: 70px;
    text-align: center;
    position: relative;
    @include m.for-tb(){
      width:100%;
    }
    @include m.for-sp(){
      padding-top: 0;
    }
    a{
      color: v.$DGREEN;
    }
    .page-navigation-prev,.page-navigation-next{
      width:440px;
      position: absolute; top:0;
      a{
        display: flex; align-items: center; justify-content: center;
        min-height:50px; padding:13px 20px;
        line-height: 1.5;
        background: #fff;
        border: 1px solid v.$DGREEN; border-radius:10px;
        transition: background v.$HOVER;
        @include m.hover{
          text-decoration: none;
          background: v.$BGC_GREEN;
        }
      }
      @include m.for-tb(){
        width:48%; 
        a{
          padding: 13px;
        }
      }
      @include m.for-sp(){
        width:45px; top:-12px;
        a{
          &:before{
            content:"";
            width:10px; height: 10px; border-top: 2px solid v.$DGREEN;border-right: 2px solid v.$DGREEN;
          }
  
        }
      }
    }
    .page-navigation-prev{
      left:0;
      @include m.for-tb(){
        a{
          border-radius: 0 10px 10px 0;
          border-left: none;
        }
      }
      @include m.for-sp(){
        a:before{
          transform: rotate(-135deg);
        }
      }
    }
    .page-navigation-next{
      right:0;
      @include m.for-tb(){
        a{
          border-radius: 10px 0 0 10px;
          border-right: none;
        }
      }
      @include m.for-sp(){
        a:before{
          transform: rotate(45deg);
        }
      }
    }
  }
  .page-body{
    figure{
      margin-top: 30px;
    }
    table{
      font-size: 1.5rem; letter-spacing: 0;
      @include m.for-sp(){
        font-size:1.3rem;
      }
      th,td{
        border: 1px solid v.$BDC;
      }
    }
  }
  .column-table-wrap{
    @include m.for-tb(){
      overflow-x: scroll;
      table{
        width:960px;
      }
    }
    @include m.for-sp(){
      table{
        width:840px;
      }
    }
  }
  .am-search-area{
    margin-top: 60px;
    padding: 20px;
    background: v.$BGC_CREAM;
    border-radius: 10px;
  }
  .am-sub-tit{
    position: relative;
    padding-left: 20px;
    &:before{
      content: "";
      width: 10px;
      height: 2px;
      background-color: v.$DGREEN;
      border-radius: 9999px;
      position: absolute;
      top: 0.6em;
      left: 0;
    }
    @include m.for-sp(){
      font-size: 1.8rem;
    }
  }
  .head-search-form{
    margin-top: 20px;
    @include m.for-sp(){
      margin-top: 10px;
    }
    form{
      display: flex; gap:20px;
      @include m.for-sp(){
        gap:10px;
      }
    }
    input[type="text"]::placeholder{
      @include m.for-sp(){
        font-size:1.3rem;
      }
    }
  }
  .search-btn{
    display: block;
    width: 80px;
    
    color: #fff;
    font-size: 1.8rem;
    font-weight: 600;
    border: 1px solid v.$DGREEN;
    border-radius: 10px;
    background: v.$DGREEN;
    padding: 0;
    transition: all v.$HOVER;
    @include m.hover{
      text-decoration: none;
      background: v.$GREEN; color: #fff;
    }
    @include m.for-sp(){
      width: 60px;
      font-size: 1.5rem;
    }
    &:hover{
      cursor: pointer; 
    }
  }
}

.seminar-tb{
  margin-top: 20px;
  font-size:1.7rem;
  @include m.for-sp(){
    width:100vw !important;
    position: relative; left:-30px !important;
  }
  th,td{
    border: none;
    padding:12px 18px;
    @include m.for-tb(){
      display: block; align-items: center;
      padding: 0 !important;
    }
  }
  
  thead{
    .th1,.th2{
      text-align: left;
    }
    .th1{
      width:240px;
    }
    .th3,.th4{
      width: 170px;
    }
    @include m.for-tb(){
      tr{
        display: flex; justify-content: space-between;
        font-size:1.4rem;
      }
      th{
        padding:8px 20px !important;
      }
      .th1,.th3{display: none;}
      
      .th4{
        width:160px; text-align: center;
        border-left: 1px solid rgba(#fff,0.3);
      }
    }
    @include m.for-sp(){
      display: none;
    }
  }
  tbody{
    tr{
      background: #fff;
      &:nth-child(2n){
        background: #EFFDF3;
      }
      @include m.for-tb(){
        display: grid;
        grid-template-columns: 1fr 160px;
        grid-template-rows: 1fr 1fr 1fr;
        padding:15px 0 15px 20px;
      }
      @include m.for-sp(){
        display: block;
        padding:12px 30px;
      }
    }
    .seminar-tb-date{
      font-size:1.6rem;letter-spacing: 0.05em;
      .date,.time{display: block;}
      @include m.for-tb(){
        grid-column:1;
        grid-row:1;
        .date,.time{display: inline-block;}
      }
      @include m.for-sp(){
        font-size:1.4rem;
      }
    }
    .seminar-tb-tit{
      a{
        color: v.$DGREEN; font-weight: bold;
      }
      @include m.for-tb(){
        grid-column:1; grid-row:2;
        font-size:2rem;font-weight: 700; color: v.$BLACK;
      }
      @include m.for-sp(){
        font-size: 1.6rem;
      }
    }
    .seminar-tb-place{
      text-align: center;
      @include m.for-tb(){
        grid-column:1; grid-row:3;
        margin-top: 5px;
        font-size:1.3rem; text-align: left;
        &:before{
          content:"会場：";
        }
      }
    }
    .seminar-tb-entry{
      padding: 12px 0;
      text-align: center;
      @include m.for-tb(){
        display: flex; align-items: center; justify-content: center;
        grid-column:2; grid-row: 1 / 4;
        padding: 0;
      }
    }
  }
  .btn{
    justify-content: center;
    width:130px; height: 40px; padding: 0; margin: 0 auto;
    color: v.$GREEN;
    border-radius: 20px; border: 1px solid v.$GREEN;
    background: #fff;
    transition: all v.$HOVER;
    @include m.hover{
      text-decoration: none;
      background: v.$GREEN; color: #fff;
    }
    &:after{display: none;}
  }
  &.seminar-offline{
    .seminar-tb-entry{
      @include m.for-sp(){
        display: block;
        width:100%;
        font-size:1.3rem; text-align: left;
        &:before{
          content:"お申し込み："
        }
      }
    }
  }
}

#seminar{
  article{
    background-color: v.$BGC_CREAM;
  }
  .seminar-tb{
    font-size:1.6rem;
    .th1{
      width:185px;
    }
    .seminar-tb-date{
      font-size:1.5rem;
    }
    
  }
  .seminar-outline{
    margin-top: 80px;
  }
  .seminar-teacher{
    display: flex; align-items: flex-start;
    margin-top: 30px;
    @include m.for-sp(){
      display: block;
    }
    .seminar-teacher-image{
      flex-shrink: 0;
      width:270px; margin-right: 30px;
      border-radius: 20px;
      overflow: hidden;
      @include m.for-sp(){
        width:240px; height:240px; margin:0 auto;
      }
    }
    .seminar-teacher-name{
      font-size:3rem;
      @include m.for-sp(){
        font-size:2.1rem;
      }
    }
    .seminar-teacher-text{
      p{
        margin-top: 15px;
      }
      @include m.for-sp(){
        margin-top: 15px;
      }
    }
  }
  .seminar-movie{
    width:100%; height: 0;padding-bottom: 56.25%; margin:30px auto 0;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    iframe{
      width:100%; height:100%;
      position: absolute; left:0; top:0;
    }
  }
  .book-list{
    display: flex; justify-content: space-between; gap:4%; align-items: center;
    li{
      width:21%;
      img{
        width:100%;
      }
    }
  }

  .seminar-form-ifa{
    margin-top: 40px;
  }
  .anchor-box{
    display: flex;
    margin-top: 40px;
    background: #fff;
    border: 1px solid v.$DGREEN; border-radius:10px;
    overflow: hidden;
    @include m.for-sp(){
      display: block;
      position: relative;
      overflow: visible;
    }
  }
  .anchor-tit{
    display:flex; justify-content:center; align-items:center;
    width:100px;
    font-family: v.$ROBOTO; font-weight: bold;
    background: v.$BGC_GREEN;
    @include m.for-sp(){
      width:80px; height: 30px;
      font-size: 1.2rem; color: #fff;
      background: v.$DGREEN;
      border-radius: 5px;
      position: absolute; left:20px; top:-15px;
    }
  }
  .anchor-list{
    display: flex; gap: 30px;
    margin-top: 0; padding:20px 30px;
    @include m.for-tb(){
      flex-wrap: wrap; gap: 10px 0;
      width:calc(100% - 100px);
      li{
        width:20%;
      }
    }
    @include m.for-sp(){
      gap: 5px 0;
      width:100%; padding: 20px 30px 15px;
    }
  }

  .ifa-list{
    display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap:10px 1%;
    margin-top: 20px;
    @include m.for-tb(){
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include m.for-sp(){
      grid-template-columns: 1fr 1fr 1fr; gap:5px;
    }
    li{
      font-size:1.8rem;
      position: relative;
      @include m.for-sp(){
        font-size:1.4rem; letter-spacing: 0;
      }
    }
    input[type="radio"]{
      position: absolute; left:10px;top:18px;
      &:checked + label{
        background-color: v.$BGC_GREEN;
      }
      @include m.for-sp(){
        opacity: 0;
      }
    }
    label{
      display: block;
      padding: 4px 0 4px 30px;
      background-color: #fff;
      border: 1px solid v.$DGREEN; border-radius: 10px;
      transition: background v.$HOVER;
      @include m.hover{
        background-color: v.$BGC_GREEN;
      }
      @include m.for-sp(){
        padding:4px;
      }
    }
  }
  .seminar-form-tb{
    td#shimei{
      input{width:150px;}
      label{
        display: inline-block;
      }
      label + label{
        margin-left: 20px;
      }
      @include m.for-sp(){
        display: flex; gap:10px;
        input{
          width:calc(100% - 2em);
        }
        label + label{
          margin-left: 0;
        }
      }
    }
  }
  .seminar-form-block{
    margin-top: 60px;
    .seminar-form-tit{
      @include m.for-sp(){
        font-size:1.8rem;
      }
    }
    .seminar-form-parts{
      margin-top: 30px;
    }
  }
  //シミュレーション
  .simulation-article{
    padding-bottom: 100px;
    position: relative;
    @include m.for-tb(){
      padding-bottom: 0;
    }
    &:before{
      content:"Simulation";
      font-family: v.$ROBOTO; font-size: 13rem;color: v.$DGREEN; font-weight: 700; white-space: nowrap;
      position: absolute; left:50px; top:200px; z-index: 1;
      opacity: 0.1;
      transform: rotate(90deg); transform-origin: left center;
    }
    &:after{
      content:"";
      width:100%;height:150px;
      background: linear-gradient(0deg,#fff 0%, v.$BGC_CREAM 100%);
      position: absolute; left:0; bottom:0;
      @include m.for-tb(){
        height:80px;
      }
    }
    .page-body{
      position: relative; z-index: 2;
    }
  }
  .simulation-setting{
    display: flex; justify-content: space-between; align-items: flex-start;
    @include m.for-tb(){
      flex-direction: column; gap:40px;
    }
  }
  .simulation-input{
    width:55%;
    @include m.for-tb(){
      width: 100%;
    }
    [name="init_val"],[name="m_deposit"]{
      width:140px;
    }
    [name="m_withdrawl_rate"]{
      width:80px;
    }
    table{
      margin-top: 15px;
    }
    .btn-area{
      margin-top: 15px;
      .btn{
        width:60%; border: none; justify-content: center;
      }
    }
  }
  .simulation-input-tit{
    font-size:2.1rem;
  }
  .simulation-result{
    width:40%; padding: 24px;
    background: #fff;
    border: 1px solid v.$DGREEN; border-radius: 20px;
    @include m.for-tb(){
      width: 100%;
    }
    p{
      margin-top: 12px;padding-bottom: 12px;border-bottom: 1px dashed v.$BDC;
      &:last-child{
        border: none;padding-bottom: 0;
      }
    }
  }
  .simulation-result-tit{
    font-size:2.1rem; margin-bottom: 20px;
  }

  .simulation-graph{
    margin-top: 80px; padding:24px 10px;
    background: #fff;
    @include m.for-tb(){
      height:400px; margin-top: 30px;
    }
    @include m.for-sp(){
      width:100dvw; height:300px;
      position: relative; left:-30px;
    }
  }

  .simulation-tb-wrap{
    display: flex; justify-content: space-between;
    margin-top: 80px;
    @include m.for-tb(){
      flex-direction: column;
      margin-top: 30px; gap:30px;
    }
    .simulation-tb-accumulation,.simulation-tb-withdrawal{
      width:48%;
      @include m.for-tb(){
        width: 100%;
      }
      table{
        table-layout: fixed;
        thead{
          @include m.for-sp(){
            font-size: 1.2rem;
          }
          th:nth-child(1){
            width:80px !important;
            @include m.for-sp(){
              width: 60px !important; padding: 5px;
            }
          }
        }
        td{
          text-align: right;
          &:nth-child(1){
            text-align: center;
          }
          @include m.for-sp(){
            padding: 7px 7px 7px 0;
          }
        }
      }
    }
  }
  .simulation-caution{
    font-size:1.4rem;
  }

  .thanks-box{
    width:800px; padding: 60px 0; margin: 0 auto;
    text-align: center;
    border-radius:20px;
    background: #fff;
    @include m.for-tb(){
      width:100%; padding:60px 50px;
      p{
        text-align: left;
      }
    }
    @include m.for-sp(){
      padding: 30px;
    }
  }

}

#ifa{
  //一覧
  .anchor-box{
    display: flex;
    margin-top: 40px;
    border: 1px solid v.$DGREEN; border-radius:10px;
    overflow: hidden;
    @include m.for-sp(){
      display: block;
      position: relative;
      overflow: visible;
    }
  }
  .anchor-tit{
    display:flex; justify-content:center; align-items:center;
    width:100px;
    font-family: v.$ROBOTO; font-weight: bold;
    background: v.$BGC_GREEN;
    @include m.for-sp(){
      width:80px; height: 30px;
      font-size: 1.2rem; color: #fff;
      background: v.$DGREEN;
      border-radius: 5px;
      position: absolute; left:20px; top:-15px;
    }
  }
  .anchor-list{
    display: flex; gap: 30px;
    margin-top: 0; padding:20px 30px;
    @include m.for-tb(){
      flex-wrap: wrap; gap: 10px 0;
      width:calc(100% - 100px);
      li{
        width:20%;
      }
    }
    @include m.for-sp(){
      gap: 5px 0;
      width:100%; padding: 20px 30px 15px;
      li.anchor-ifa{
        width:100%;
      }
    }
  }
  [id^="anchor-"]{
    margin-top: 60px; padding-bottom: 5px;
    border-bottom: 2px solid v.$GREEN;
    @include m.for-sp(){
      margin-top: 40px;
    }
  }
  .ifa-list{
    display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap:30px 20px;
    @include m.for-tb(){
      grid-template-columns: 1fr 1fr 1fr 1fr; gap:20px 20px;
    }
    @include m.for-sp(){
      grid-template-columns: 1fr 1fr 1fr; gap:20px 10px;
    }

  }
  .ifa-list-item{
    a{
      display: flex; align-items: center; gap: 15px;
      
    }
  }
  .ifa-list-image{
    flex-shrink: 0;
    width:80px; height: 80px; overflow: hidden;
    border-radius:10px;
    img{
      width: 100%; height: 100%; object-fit: cover;
    }
  }
  .ifa-list-name{
    font-size:1.8rem; font-weight: bold; line-height: 1.3;
    @include m.for-sp(){
      font-size: 1.4rem;
    }
  }

  //詳細
  .ifa-detail{
    display: flex; justify-content: space-between; align-items: center;
    @include m.for-sp(){
      flex-direction: column; gap:20px;
    }
  }
  .ifa-detail-image{
    width:240px; height: 320px;
    border-radius: 20px; overflow: hidden;
    img{
      width:100%; height: 100%;
      object-fit: cover;
    }
    @include m.for-sp(){
      height:225px; height: 300px;
    }
  }
  .ifa-detail-text{
    width: calc(100% - 300px);
    @include m.for-sp(){
      width:100%;
    }
  }
  .ifa-detail-tit{
    @include m.for-sp(){
      text-align: center;
    }
    .ifa-detail-name{
      font-size:4rem;
      @include m.for-sp(){
        font-size:2.4rem;
      }
    }
    .ifa-detail-kana{
      display: block;
      padding-top: 5px;
      font-size:1.6rem;
      @include m.for-sp(){
        font-size:1.3rem;
      }
    }
  }
  .ifa-detail-info{
   
    dt{
      font-size:1.4rem; font-weight: bold;
      margin-top: 10px;
    }
    dd{
      line-height: 1.5;
    }
  }
  .ifa-detail-profile{
    margin-top: 60px;
    @include m.for-sp(){
      margin-top: 40px;
    }
  }
  .ifa-detail-stit{
    font-size:2.4rem;
    @include m.for-sp(){
      font-size: 1.8rem;
    }
  }
}

#contact{
  article{
    padding-bottom: 0;
  }
  .page-body{
    iframe{
      width:100%;
    }
    .teQAzf{
      width:960px;
    }
  }
}

#policy,#privacy,#terms{
  .page-body{
    h2{
      font-size: 2.6rem;
    }
    h3{
      font-size:2.2rem;
    }
  }
}
#disclosure{
  .page-tit-wrap{
    .page-tit{
      white-space: nowrap; letter-spacing: 0.1em;
    }
  }
  .img-disclosure{
    border: 1px solid v.$BDC;
  }
}