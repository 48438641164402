@use "../settings/vars" as v;
@use "../settings/mixins" as m;

//form
input,button,textarea,select{font-family:inherit;}
input[type="button"],input[type="submit"]{
	font-family:inherit;appearance: none; margin: 0;
}
input[type="text"],input[type="number"],input[type="tel"],input[type="password"],input[type="email"]{
	appearance: none; 
	width:100%;	height:44px; padding:0 14px;
	border: 1px solid v.$BDC;	border-radius:5px;	
	background: #fff;
	@include m.for-sp(){
		padding:0 8px;
	}
}
input[type="number"]:not([name="m_withdrawl_rate"])::-webkit-outer-spin-button, 
input[type="number"]:not([name="m_withdrawl_rate"])::-webkit-inner-spin-button { 
  -webkit-appearance: none;
  margin: 0; 
} 
input[type="number"]:not([name="m_withdrawl_rate"]){
	-moz-appearance:textfield;
}
input[type="number"][name="m_withdrawl_rate"]{
	padding-right: 5px;
}


input:read-only{
	background: v.$BGC_GRAY;
}

//テキストボックス
textarea{
	width:100%; min-height: 200px; padding:14px;
	border: 1px solid v.$BDC;	border-radius:5px;	
	background: #fff;
}


@include m.for-sp(){
	input[type="text"],input[type="number"],input[type="password"],input[type="email"],input[type="tel"],textarea,select{font-size: 1.6rem;}
}

//セレクトボックス
.select-wrap{
	position: relative; display: inline-block;max-width: 100%;
	border-radius: 5px; background: #fff;
	&:after{content:"›";color: v.$BLACK;position: absolute;right:11px;top:calc(50% - 0.5em);line-height: 1;
		transform: rotate(90deg);z-index: 1;font-size: 1.6rem;
	}
	&:before{
		content:"";width: 1px;height: 100%;position: absolute;right:32px;top:0;background: v.$BDC;
	}
	&.error{
		background: v.$BGC_RED;
	}
}

select{
	max-width: 100%; height: 44px; margin: 0; padding: 0 50px 0 14px;
	border: 1px solid v.$BDC;appearance: none;border-radius: 5px;
	background: transparent;position: relative;z-index: 2;
	
}
select::-ms-expand { display: none;}


//ラジオボタン
input[type="radio"],input[type="checkbox"]{margin-right: 5px;}


:placeholder-shown {color: #bbb; }
::-webkit-input-placeholder {color:#bbb; }
::-moz-placeholder {color: #bbb; opacity: 1; }
:-ms-input-placeholder {color:#bbb; }

input.error{
	background: v.$BGC_RED;
}
label.error{
	font-size:1.2rem;line-height: 1.5; color: v.$RED;
	&:before{
		content:"※";
	}

}