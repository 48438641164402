//print
@use "settings/vars" as v;

@media print{
	body{
		zoom: 0.65;
	}
	#wrapper{
		min-width:0;
	}
	
	.anm{
		opacity: 1;top:0;
	}
}
