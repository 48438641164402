@charset "UTF-8";
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:where([hidden]:not([hidden=until-found])) {
  display: none !important;
}

:where(html) {
  -webkit-text-size-adjust: none;
}

:where(body) {
  line-height: 1.5;
  font-family: system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
}

:where(input, button, textarea, select) {
  font: inherit;
  color: inherit;
}

:where(textarea) {
  resize: vertical;
  resize: block;
}

:where(button, label, select, summary, [role=button], [role=option]) {
  cursor: pointer;
}

:where(:disabled) {
  cursor: not-allowed;
}

:where(label:has(> input:disabled), label:has(+ input:disabled)) {
  cursor: not-allowed;
}

:where(button) {
  border-style: solid;
}

:where(a) {
  color: inherit;
  text-underline-offset: 0.2ex;
}

:where(ul, ol) {
  list-style: none;
}

:where(img, svg, video, canvas, audio, iframe, embed, object) {
  display: block;
}

:where(img, picture, svg) {
  max-inline-size: 100%;
  block-size: auto;
}

:where(p, h1, h2, h3, h4, h5, h6) {
  overflow-wrap: break-word;
}

:where(h1, h2, h3) {
  line-height: calc(1em + 0.5rem);
}

:where(hr) {
  border: none;
  border-block-start: 1px solid;
  color: inherit;
  block-size: 0;
  overflow: visible;
}

:where(:focus-visible) {
  outline: 3px solid Highlight;
  outline-offset: 2px;
  scroll-margin-block: 10vh;
}

:where(.visually-hidden:not(:focus-within, :active)) {
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  border: 0 !important;
}

*, :before, :after {
  box-sizing: border-box;
  word-break: break-all;
}

html {
  font-size: 62.5%;
}

img {
  vertical-align: middle;
  display: inline-block;
}

ol {
  list-style-position: inside;
}

strong {
  font-weight: bold;
}

sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: #151515;
}

body {
  font-size: 1.6rem;
  font-family: YakuHanJP, -apple-system, "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "Hiragino Kaku Gothic ProN", sans-serif;
  color: #333;
  line-height: 2;
  letter-spacing: 0.1em;
  background: #fff;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
body.fixed {
  width: 100%;
  height: 100%;
  position: fixed;
}

@media (max-width: 559px) {
  body {
    font-size: 1.4rem;
  }
}
::selection {
  background: rgba(24, 138, 28, 0.7);
  color: #fff;
}

input, button, textarea, select {
  font-family: inherit;
}

input[type=button], input[type=submit] {
  font-family: inherit;
  appearance: none;
  margin: 0;
}

input[type=text], input[type=number], input[type=tel], input[type=password], input[type=email] {
  appearance: none;
  width: 100%;
  height: 44px;
  padding: 0 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}
@media (max-width: 559px) {
  input[type=text], input[type=number], input[type=tel], input[type=password], input[type=email] {
    padding: 0 8px;
  }
}

input[type=number]:not([name=m_withdrawl_rate])::-webkit-outer-spin-button,
input[type=number]:not([name=m_withdrawl_rate])::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]:not([name=m_withdrawl_rate]) {
  -moz-appearance: textfield;
}

input[type=number][name=m_withdrawl_rate] {
  padding-right: 5px;
}

input:read-only {
  background: #f1f1f1;
}

textarea {
  width: 100%;
  min-height: 200px;
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}

@media (max-width: 559px) {
  input[type=text], input[type=number], input[type=password], input[type=email], input[type=tel], textarea, select {
    font-size: 1.6rem;
  }
}
.select-wrap {
  position: relative;
  display: inline-block;
  max-width: 100%;
  border-radius: 5px;
  background: #fff;
}
.select-wrap:after {
  content: "›";
  color: #151515;
  position: absolute;
  right: 11px;
  top: calc(50% - 0.5em);
  line-height: 1;
  transform: rotate(90deg);
  z-index: 1;
  font-size: 1.6rem;
}
.select-wrap:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: 32px;
  top: 0;
  background: #ccc;
}
.select-wrap.error {
  background: #ffe1e3;
}

select {
  max-width: 100%;
  height: 44px;
  margin: 0;
  padding: 0 50px 0 14px;
  border: 1px solid #ccc;
  appearance: none;
  border-radius: 5px;
  background: transparent;
  position: relative;
  z-index: 2;
}

select::-ms-expand {
  display: none;
}

input[type=radio], input[type=checkbox] {
  margin-right: 5px;
}

:placeholder-shown {
  color: #bbb;
}

::-webkit-input-placeholder {
  color: #bbb;
}

::-moz-placeholder {
  color: #bbb;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bbb;
}

input.error {
  background: #ffe1e3;
}

label.error {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #d30c0c;
}
label.error:before {
  content: "※";
}

a {
  text-decoration: none;
  color: #4848ac;
}
a img {
  transition: opacity 0.25s linear;
}
@media (hover: hover) and (pointer: fine) {
  a:hover {
    text-decoration: underline;
  }
  a:hover img {
    opacity: 0.7;
  }
}

.tbdisp {
  display: none;
}

.spdisp {
  display: none;
}

@media (max-width: 959px) {
  .tbdisp {
    display: block;
  }
  .tbhide {
    display: none;
  }
}
@media (max-width: 559px) {
  .spdisp {
    display: block;
  }
  .sphide {
    display: none;
  }
}
.cf:after {
  content: "";
  display: block;
  clear: both;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.fl-left {
  float: left;
}

.fl-right {
  float: right;
}

.cl {
  clear: both;
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: min-content;
  height: 64px;
  padding: 0 25px 0 30px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
  border: none;
  border-radius: 32px;
  background: #396464;
  position: relative;
  overflow: hidden;
}
@media (max-width: 559px) {
  .btn {
    max-width: 100%;
  }
}
.btn:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #188A1C, #396464);
  position: absolute;
  left: -100%;
  top: 0;
  transition: left 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.btn .label {
  width: max-content;
  position: relative;
  z-index: 2;
}
.btn .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 31px;
  height: 31px;
  background: #D7E0E0;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}
.btn .icon svg {
  fill: #396464;
}
@media (hover: hover) and (pointer: fine) {
  .btn:hover {
    text-decoration: none;
  }
  .btn:hover:after {
    left: 0;
  }
}
.btn.btn-submit {
  min-width: 240px;
}
.btn.btn-back {
  justify-content: center;
  min-width: 100px;
  height: 48px;
  padding: 0 20px;
  font-size: 1.6rem;
  background: #999;
  border-radius: 24px;
  transition: opacity 0.25s linear;
}
.btn.btn-back:after {
  display: none;
}
@media (hover: hover) and (pointer: fine) {
  .btn.btn-back:hover {
    opacity: 0.7;
  }
}
@media (max-width: 559px) {
  .btn {
    height: 56px;
    padding: 0 20px 0 25px;
    gap: 14px;
    font-size: 1.6rem;
  }
}

.btn-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}
@media (max-width: 559px) {
  .btn-area {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 559px) {
  form .btn-area {
    flex-direction: column-reverse;
  }
}

.l {
  font-size: 1.8rem;
  display: inline-block;
}

.s {
  font-size: 1.4rem;
  display: inline-block;
}

.xs {
  font-size: 1.2rem;
  display: inline-block;
}

@media (max-width: 559px) {
  .xs {
    font-size: 1rem;
  }
  .s {
    font-size: 1.2rem;
  }
  .l {
    font-size: 1.6rem;
  }
}
.red {
  color: #d30c0c;
}

.green {
  color: #188A1C;
}

.page-tit-wrap {
  height: 200px;
  background: #396464;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.page-tit-wrap:after {
  content: "";
  width: 100%;
  height: 30px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}
@media (max-width: 559px) {
  .page-tit-wrap {
    height: 120px;
  }
}
.page-tit-wrap .page-tit-box {
  width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 959px) {
  .page-tit-wrap .page-tit-box {
    width: 100%;
    padding-left: 30px;
  }
}
.page-tit-wrap .page-tit {
  font-size: 4rem;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-shadow: rgba(0, 0, 0, 0.15) 0 0 10px;
}
.page-tit-wrap .page-tit .s {
  font-size: 3rem;
}
@media (max-width: 959px) {
  .page-tit-wrap .page-tit {
    font-size: 3.4rem;
    position: relative;
    z-index: 2;
  }
  .page-tit-wrap .page-tit .s {
    font-size: 2.2rem;
  }
}
@media (max-width: 559px) {
  .page-tit-wrap .page-tit {
    font-size: 2.4rem;
  }
  .page-tit-wrap .page-tit .s {
    font-size: 1.8rem;
  }
}
.page-tit-wrap .page-tit-image {
  position: relative;
  right: -100px;
  z-index: 1;
}
@media (max-width: 959px) {
  .page-tit-wrap .page-tit-image {
    position: absolute;
    bottom: 0;
  }
}
@media (max-width: 559px) {
  .page-tit-wrap .page-tit-image {
    width: 200px;
  }
}

.page-body {
  width: 960px;
  margin: 0 auto;
  padding: 60px 0 90px;
}
@media (max-width: 959px) {
  .page-body {
    width: 100%;
    padding: 60px 50px 90px;
  }
}
@media (max-width: 559px) {
  .page-body {
    width: 100%;
    padding: 30px 30px 50px;
  }
}
.page-body a:not([class]) {
  text-decoration: underline;
  color: #396464;
}
@media (hover: hover) and (pointer: fine) {
  .page-body a:not([class]):hover {
    text-decoration: none;
  }
}
.page-body > *:first-child {
  margin-top: 0 !important;
}
.page-body p, .page-body ol, .page-body ul, .page-body dl, .page-body table {
  margin-top: 30px;
}
@media (max-width: 559px) {
  .page-body p, .page-body ol, .page-body ul, .page-body dl, .page-body table {
    margin-top: 24px;
  }
}
.page-body h2, .page-body h3, .page-body h4, .page-body h5, .page-body h6 {
  line-height: 1.2;
  margin-top: 30px;
}
.page-body h2:first-child, .page-body h3:first-child, .page-body h4:first-child, .page-body h5:first-child, .page-body h6:first-child {
  margin-top: 0 !important;
}
.page-body h2 {
  font-size: 3.2rem;
  margin-top: 50px;
}
@media (max-width: 559px) {
  .page-body h2 {
    font-size: 2.4rem;
    margin-top: 30px;
  }
}
.page-body h3 {
  font-size: 2.4rem;
}
@media (max-width: 559px) {
  .page-body h3 {
    font-size: 2rem;
  }
}
.page-body h4 {
  font-size: 2.1rem;
}
@media (max-width: 559px) {
  .page-body h4 {
    font-size: 1.8rem;
  }
}
.page-body h5 {
  font-size: 1.8rem;
}
@media (max-width: 559px) {
  .page-body h5 {
    font-size: 1.6rem;
  }
}
.page-body ul:not([class]) li {
  padding-left: 15px;
  position: relative;
  margin-top: 0.5em;
  line-height: 1.5;
}
.page-body ul:not([class]) li:before {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  background: #188A1C;
  border-radius: 1px;
  position: absolute;
  left: 2px;
  top: calc(0.9em - 3px);
}
.page-body ol:not([class]) {
  list-style-type: decimal;
  line-height: 1.5;
}
.page-body ol:not([class]) li {
  margin-top: 0.5em;
}
.page-body img {
  max-width: 100%;
  height: auto;
}

.box, blockquote {
  padding: 24px;
  margin-top: 30px;
  border: 1px solid #396464;
  border-radius: 10px;
}
.box > *:first-child, blockquote > *:first-child {
  margin-top: 0 !important;
}
.box h2, blockquote h2, .box h3, blockquote h3, .box h4, blockquote h4, .box h5, blockquote h5, .box h6, blockquote h6 {
  font-size: 2rem;
}
.box > * + *, blockquote > * + * {
  margin-top: 15px !important;
}

blockquote {
  background: #f1f1f1;
  position: relative;
}
blockquote:before {
  content: "“";
  position: absolute;
  left: 10px;
  top: -40px;
  font-size: 10rem;
  color: #396464;
  line-height: 1;
  font-weight: 700;
}
blockquote:after {
  content: "”";
  position: absolute;
  right: 10px;
  bottom: -60px;
  font-size: 10rem;
  color: #396464;
  line-height: 1;
  font-weight: 700;
}

.block {
  padding: 2em 0;
}
.block:first-child {
  padding-top: 0;
}
.block > * + * {
  margin-top: 1.5em;
}

.breadcrumb {
  display: flex;
  align-items: center;
  width: 1020px;
  height: 40px;
  padding: 0 30px;
  margin: 0 auto;
  font-size: 1.2rem;
  background: #E8EDD4;
  border-radius: 10px;
  position: relative;
  top: -20px;
  z-index: 2;
}
@media (max-width: 959px) {
  .breadcrumb {
    width: 100%;
    border-radius: 0;
    top: 0;
  }
}
@media (max-width: 559px) {
  .breadcrumb {
    font-size: 1rem;
    height: 30px;
  }
}
.breadcrumb li {
  display: inline-block;
}
.breadcrumb li:before {
  content: "›";
  display: inline-block;
  margin: 0 5px 0 2px;
  color: #333;
}
.breadcrumb li:first-child:before {
  display: none;
}
.breadcrumb li a {
  color: #396464;
}
.breadcrumb li.current {
  color: #151515;
  font-weight: 700;
}
@media (max-width: 559px) {
  .breadcrumb li:nth-child(n+3) {
    display: none;
  }
}

table {
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.7;
  border-collapse: collapse;
  background: #fff;
}
table th, table td {
  padding: 12px 10px;
}
table tr {
  border-bottom: 1px solid #ccc;
}
table thead {
  background: #188A1C;
}
table thead th {
  font-weight: bold;
  text-align: center;
  color: #fff;
}
table tbody th {
  font-weight: bold;
  text-align: left;
  background: #E8EDD4;
  width: 25%;
}

@media (max-width: 559px) {
  table {
    font-size: 1.4rem;
  }
  table:not(.nobreak) {
    width: calc(100% + 20px);
    position: relative;
    left: -10px;
  }
  table:not(.nobreak) tr, table:not(.nobreak) th, table:not(.nobreak) td {
    display: block;
    border: none;
    padding: 0;
    width: 100%;
  }
  table:not(.nobreak) thead {
    display: none;
  }
  table:not(.nobreak) tbody tr {
    border-bottom: 1px solid #ccc;
  }
  table:not(.nobreak) tbody th {
    padding: 5px 10px;
  }
  table:not(.nobreak) tbody td {
    padding: 10px 10px;
  }
}
hr {
  border: none;
  border-bottom: 1px solid #ccc;
  padding-top: 80px;
  margin-bottom: 80px;
}

.paginate {
  padding-top: 50px;
  margin: 0 auto;
}

.paginate-list {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 12px;
  font-family: "Roboto", sans-serif;
}
.paginate-list a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: #396464;
  text-decoration: none !important;
  letter-spacing: 0;
  border: 1px solid #396464;
  border-radius: 10px;
  background: #fff;
  transition: all 0.25s linear;
}
@media (hover: hover) and (pointer: fine) {
  .paginate-list a:hover {
    background: #E8EDD4;
  }
}
.paginate-list .disabled a {
  color: #ccc;
  pointer-events: none;
}
.paginate-list .current {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: #fff;
  font-weight: bold;
  border: 1px solid #396464;
  border-radius: 10px;
  background: #396464;
}

@media (max-width: 559px) {
  .paginate {
    width: 100%;
    padding: 30px 3vw 0;
  }
}
@media (max-width: 559px) {
  .paginate-list {
    gap: 6px;
  }
  .paginate-list a, .paginate-list .current {
    width: 30px;
    height: 30px;
    font-size: 1.3rem;
  }
}
#fs-paginate .paginate-list .fs-current a {
  color: #fff;
  border: 1px solid #396464;
  background: #396464;
  pointer-events: none;
}

#wrapper {
  width: 100%;
  min-width: 1000px;
  overflow-x: hidden;
}

@media (max-width: 959px) {
  #wrapper {
    min-width: 0;
  }
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  padding: 0 30px;
  background: rgba(255, 255, 255, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
@media screen and (max-width: 1280px) {
  header {
    padding: 0 10px 0 15px;
  }
}
@media (max-width: 559px) {
  header {
    height: 70px;
  }
}
header .logo {
  width: 150px;
}
header .logo img {
  width: 100%;
  height: auto;
}
@media (max-width: 559px) {
  header .logo {
    width: 120px;
  }
}

#menu-btn {
  display: none;
}

.gnavi {
  display: flex;
  align-items: center;
  font-weight: 700;
}
.gnavi .gnavi-list {
  display: flex;
  gap: 2em;
}
@media screen and (max-width: 1280px) {
  .gnavi .gnavi-list {
    font-size: 1.5rem;
  }
}
.gnavi .gnavi-list > li {
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  .gnavi .gnavi-list > li:hover > a:before {
    transform: scaleX(1);
    transform-origin: left center;
  }
  .gnavi .gnavi-list > li:hover .gnavi-drop {
    opacity: 1;
    visibility: visible;
  }
}
.gnavi .gnavi-list a {
  display: inline-block;
  padding: 12px 0;
  color: #396464;
  line-height: 1;
  position: relative;
}
.gnavi .gnavi-list a:before {
  content: "";
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background: #396464;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@media (hover: hover) and (pointer: fine) {
  .gnavi .gnavi-list a:hover {
    text-decoration: none;
  }
}
.gnavi .gnavi-list .active > a:before {
  transform: scaleX(1);
}
.gnavi .gnavi-drop {
  opacity: 0;
  visibility: hidden;
  width: 260px;
  padding: 10px 20px;
  font-size: 1.4rem;
  position: absolute;
  left: calc(50% - 125px);
  top: 40px;
  border-radius: 10px;
  border: 1px solid #396464;
  background: #fff;
  transition: all 0.2s linear;
}
.gnavi .gnavi-drop a {
  display: block;
  padding: 10px 0;
}
.gnavi .gnavi-drop a:before {
  height: 1px;
}
@media (hover: hover) and (pointer: fine) {
  .gnavi .gnavi-drop a:hover:before {
    transform: scaleX(1);
    transform-origin: left center;
  }
}

.head-contact {
  margin-left: 40px;
}
.head-contact .btn-contact {
  font-size: 1.6rem;
}
@media screen and (max-width: 1280px) {
  .head-contact {
    margin-left: 30px;
  }
  .head-contact .btn-contact {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1100px) {
  .head-contact {
    margin-left: 20px;
  }
  .head-contact .btn-contact {
    height: 52px;
    padding: 0 20px;
    font-size: 1.4rem;
    letter-spacing: 0.05em;
  }
  .head-contact .btn-contact .icon {
    display: none;
  }
}

@media (max-width: 959px) {
  #menu-btn {
    display: block;
    width: 60px;
    height: 60px;
    position: fixed;
    right: 20px;
    top: 15px;
    cursor: pointer;
    z-index: 999;
    overflow: hidden;
  }
  #menu-btn .menu-line {
    display: block;
    width: 34px;
    height: 2px;
    border-radius: 1px;
    background: #396464;
    position: absolute;
    left: calc(50% - 17px);
  }
  #menu-btn .menu-line:nth-child(1) {
    top: 19px;
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s;
  }
  #menu-btn .menu-line:nth-child(2) {
    top: 29px;
    transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  }
  #menu-btn .menu-line:nth-child(3) {
    top: 39px;
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s;
  }
  #menu.open #menu-btn .menu-line {
    background: #fff;
  }
  #menu.open #menu-btn .menu-line:nth-child(1) {
    top: 29px;
    transform: rotate(-135deg);
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  }
  #menu.open #menu-btn .menu-line:nth-child(2) {
    transform: scaleX(0);
  }
  #menu.open #menu-btn .menu-line:nth-child(3) {
    top: 29px;
    transform: rotate(135deg);
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  }
  #menu.open .navi-wrap {
    visibility: visible;
    opacity: 1;
  }
  .navi-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    width: 100%;
    height: 100%;
    background: rgba(57, 100, 100, 0.95);
    backdrop-filter: blur(3px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
  }
  .gnavi {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }
  .gnavi .gnavi-list {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    font-size: 3.2rem;
    line-height: 1;
  }
  .gnavi .gnavi-list a {
    color: #fff;
  }
  .gnavi .gnavi-list a:before {
    width: 200px;
    height: 2px;
    background: rgba(255, 255, 255, 0.8);
    left: -220px;
    top: calc(50% - 1px);
  }
}
@media (max-width: 959px) and (hover: hover) and (pointer: fine) {
  .gnavi .gnavi-list a:hover:before {
    display: none;
  }
}
@media (max-width: 959px) {
  .gnavi .gnavi-list .home {
    display: block;
  }
}
@media (max-width: 959px) {
  .gnavi .gnavi-drop {
    opacity: 1;
    visibility: unset;
    width: 100%;
    font-size: 2.2rem;
    background: none;
    border: none;
    position: static;
  }
  .gnavi .gnavi-drop a:before {
    display: none;
  }
}
@media (max-width: 959px) {
  .head-contact {
    margin-left: 0;
  }
  .head-contact .btn-contact {
    width: 400px;
    height: 100px;
    padding: 0 50px;
    font-size: 2.8rem;
    border: 1px solid #fff;
    border-radius: 50px;
  }
  .head-contact .btn-contact .icon {
    display: flex;
    width: 60px;
    height: 60px;
  }
  .head-contact .btn-contact .icon svg {
    transform: scale(1.5);
  }
}
@media (max-width: 559px) {
  #menu-btn {
    width: 44px;
    height: 44px;
    top: 13px;
    right: 15px;
  }
  #menu-btn .menu-line {
    width: 30px;
    left: calc(50% - 15px);
  }
  #menu-btn .menu-line:nth-child(1) {
    top: 13px;
  }
  #menu-btn .menu-line:nth-child(2) {
    top: 21px;
  }
  #menu-btn .menu-line:nth-child(3) {
    top: 29px;
  }
  #menu.open #menu-btn .menu-line:nth-child(1), #menu.open #menu-btn .menu-line:nth-child(3) {
    top: 21px;
  }
  .gnavi {
    gap: 30px;
  }
  .gnavi .gnavi-list {
    gap: 0px;
    font-size: 1.8rem;
  }
  .gnavi a:before {
    height: 1px;
  }
  .gnavi .gnavi-drop {
    padding: 0 20px;
    font-size: 1.4rem;
  }
  .gnavi .gnavi-drop a {
    padding: 5px;
  }
  .head-contact .btn-contact {
    width: 260px;
    height: 70px;
    padding: 0 30px;
    font-size: 1.8rem;
  }
  .head-contact .btn-contact .icon {
    width: 40px;
    height: 40px;
  }
  .head-contact .btn-contact .icon svg {
    transform: scale(1);
  }
}
main {
  padding-top: 90px;
  min-height: 1000px;
}
@media (max-width: 559px) {
  main {
    padding-top: 70px;
  }
}

.cta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1320px;
  height: 520px;
  margin: -200px auto 0;
  background: url(../images/bg_cta.jpg) center center no-repeat;
  background-size: cover;
  border-radius: 20px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1360px) {
  .cta {
    width: 94%;
  }
}
@media (max-width: 559px) {
  .cta {
    height: auto;
    padding: 40px 0;
  }
}
.cta .cta-box {
  text-align: center;
  color: #fff;
}
@media (max-width: 559px) {
  .cta .cta-box {
    width: 100%;
    padding: 0 30px;
  }
}
.cta .cta-tit {
  font-size: 4rem;
  color: #fff;
}
@media (max-width: 559px) {
  .cta .cta-tit {
    font-size: 2.8rem;
  }
}
.cta .cta-lead {
  margin-top: 30px;
  font-weight: 700;
  line-height: 1.75;
}
@media (max-width: 559px) {
  .cta .cta-lead {
    margin-top: 20px;
    text-align: left;
  }
}
.cta .cta-btn-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}
@media (max-width: 959px) {
  .cta .cta-btn-area {
    flex-direction: column-reverse;
    gap: 20px;
    margin-top: 20px;
  }
}
.cta .cta-tel, .cta .cta-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 180px;
  border-radius: 20px;
}
@media (max-width: 959px) {
  .cta .cta-tel, .cta .cta-form {
    height: 120px;
  }
}
@media (max-width: 559px) {
  .cta .cta-tel, .cta .cta-form {
    width: 100%;
    height: 80px;
  }
}
.cta .cta-tel {
  flex-direction: column;
  background: #fff;
}
.cta .cta-tel .cta-tel-number {
  display: inline-block;
  margin-top: 10px;
  padding-left: 40px;
  font-size: 3.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #396464;
  letter-spacing: 0;
  line-height: 1;
  background: url(../images/icon_tel.png) left center no-repeat;
  background-size: 33px;
}
@media (max-width: 559px) {
  .cta .cta-tel .cta-tel-number {
    font-size: 2.8rem;
    height: 33px;
    line-height: 33px;
  }
}
.cta .cta-tel .cta-tel-info {
  display: block;
  margin-top: 4px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  color: #333;
}
.cta .tel-wrap {
  display: block;
  width: 100%;
}
.cta .cta-form {
  background: #F7F5C9;
  font-size: 2.4rem;
  color: #396464;
}
@media (max-width: 559px) {
  .cta .cta-form {
    font-size: 2rem;
  }
}
.cta .cta-form:after {
  width: 110%;
  left: -120%;
  transform: skewX(-15deg);
  transition: all 0.25s linear;
}
.cta .cta-form .label {
  transition: color 0.25s linear 0.05s;
}
.cta .cta-form .icon {
  width: 43px;
  height: 43px;
  background: #396464;
  transition: background 0.25s linear 0.1s;
}
.cta .cta-form .icon svg {
  fill: #F7F5C9;
  transition: fill 0.25s linear 0.1s;
}
@media (hover: hover) and (pointer: fine) {
  .cta .cta-form:hover {
    text-decoration: none;
  }
  .cta .cta-form:hover:after {
    left: -5%;
    transform: skewX(0deg);
  }
  .cta .cta-form:hover .label {
    color: #fff;
  }
  .cta .cta-form:hover .icon {
    background: #D7E0E0;
  }
  .cta .cta-form:hover .icon svg {
    fill: #396464;
  }
}

footer {
  padding-bottom: 20px;
  margin-top: 150px;
  background: linear-gradient(180deg, #234720, #183B15);
  position: relative;
}
@media (max-width: 959px) {
  footer {
    margin-top: 100px;
  }
}
@media (max-width: 559px) {
  footer {
    margin-top: 70px;
  }
}
footer:before {
  content: "";
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #FFFDD2);
  position: absolute;
  left: 0;
  top: -50px;
  z-index: 1;
}
footer .foot-top {
  width: 1440px;
  height: 138px;
  background: url("../images/footer_top.png") top center no-repeat;
  background-size: cover;
  position: absolute;
  left: calc(50% - 720px);
  top: -99px;
  z-index: 2;
}
@media (max-width: 959px) {
  footer .foot-top {
    width: 768px;
    height: 100px;
    top: -71px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 559px) {
  footer .foot-top {
    width: 400px;
    height: 56px;
    top: -40px;
  }
}
footer .foot-box {
  width: 960px;
  margin: 0 auto;
  padding-top: 80px;
}
@media (max-width: 959px) {
  footer .foot-box {
    width: 100%;
    padding-top: 50px;
  }
}
footer .foot-middle {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 30px;
  border-bottom: 1px solid #627A5F;
}
@media (max-width: 959px) {
  footer .foot-middle {
    padding: 0 50px 30px;
    gap: 20px;
  }
}
@media (max-width: 559px) {
  footer .foot-middle {
    display: block;
    padding: 0 30px 20px;
  }
}
footer .foot-company .foot-company-name {
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 700;
  color: #fff;
}
footer .foot-company .foot-company-address {
  margin-top: 10px;
  font-size: 1.3rem;
  line-height: 1.5;
  color: #fff;
}
@media (max-width: 559px) {
  footer .foot-company .foot-company-address a {
    color: #fff;
  }
}
@media (max-width: 559px) {
  footer .foot-navi {
    margin-top: 20px;
  }
}
footer .foot-navi .foot-navi-list {
  display: flex;
  flex-wrap: wrap;
  width: 320px;
  font-size: 1.4rem;
}
@media (max-width: 959px) {
  footer .foot-navi .foot-navi-list {
    width: 280px;
    font-size: 1.3rem;
  }
}
footer .foot-navi .foot-navi-list li:nth-child(2n+1) {
  width: 60%;
}
footer .foot-navi .foot-navi-list li:nth-child(2n) {
  width: 40%;
}
@media (max-width: 959px) {
  footer .foot-navi .foot-navi-list li:nth-child(2n+1) {
    width: 50%;
  }
  footer .foot-navi .foot-navi-list li:nth-child(2n) {
    width: 50%;
  }
}
footer .foot-navi .foot-navi-list a {
  display: inline-block;
  color: #fff;
  position: relative;
}
footer .foot-navi .foot-navi-list a:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 3px;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@media (hover: hover) and (pointer: fine) {
  footer .foot-navi .foot-navi-list a:hover {
    text-decoration: none;
  }
  footer .foot-navi .foot-navi-list a:hover:before {
    transform: scaleX(1);
    transform-origin: left center;
  }
}
footer .foot-snavi {
  margin-top: 30px;
}
@media (max-width: 959px) {
  footer .foot-snavi {
    padding: 0 50px;
  }
}
@media (max-width: 559px) {
  footer .foot-snavi {
    margin-top: 20px;
    padding: 0 30px;
  }
}
footer .foot-snavi .foot-snavi-list {
  display: flex;
  justify-content: center;
  gap: 2.5em;
  font-size: 1.3rem;
}
@media (max-width: 959px) {
  footer .foot-snavi .foot-snavi-list {
    font-size: 1.2rem;
    gap: 5px 2em;
    flex-wrap: wrap;
  }
}
@media (max-width: 559px) {
  footer .foot-snavi .foot-snavi-list {
    flex-wrap: wrap;
  }
}
footer .foot-snavi .foot-snavi-list a {
  color: #D1D8D1;
}
footer .pagetop {
  display: none;
}
footer .copyright {
  display: block;
  margin-top: 50px;
  font-size: 1.2rem;
  color: #D1D8D1;
  text-align: center;
}
@media (max-width: 959px) {
  footer .copyright {
    margin-top: 30px;
  }
}
@media (max-width: 559px) {
  footer .copyright {
    font-size: 1rem;
  }
}

#key {
  height: 536px;
  background: url(../images/key_bg.png) top center repeat;
  background-size: 700px 536px;
  position: relative;
}
@media (max-width: 959px) {
  #key {
    height: 720px;
  }
}
@media (max-width: 559px) {
  #key {
    height: calc(100vh - 70px);
    max-height: 720px;
    min-height: 550px;
    background-size: 350px 268px;
  }
}
#key .key-image {
  width: 850px;
  position: absolute;
  right: -120px;
  bottom: -356px;
  z-index: 1;
}
@media screen and (max-width: 1280px) {
  #key .key-image {
    right: -200px;
  }
}
@media (max-width: 959px) {
  #key .key-image {
    width: 700px;
    right: 50%;
    transform: translateX(50%);
    bottom: -294px;
  }
}
@media (max-width: 559px) {
  #key .key-image {
    width: 380px;
    bottom: -159px;
  }
}
#key .key-text-box {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-top: 140px;
  position: relative;
}
@media screen and (max-width: 1280px) {
  #key .key-text-box {
    width: 100%;
    padding: 140px 50px;
  }
}
@media (max-width: 959px) {
  #key .key-text-box {
    padding-top: 60px;
    text-align: center;
  }
}
@media (max-width: 559px) {
  #key .key-text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px 200px;
    text-align: left;
  }
}
#key .key-tit {
  font-size: 4.5rem;
  line-height: 1.7;
  position: relative;
  z-index: 2;
}
#key .key-tit .key-r {
  display: inline-block;
  margin-right: 5px;
}
#key .key-tit .key-r img {
  display: block;
  position: relative;
  top: 3px;
}
@media (max-width: 959px) {
  #key .key-tit {
    display: inline-block;
  }
}
@media (max-width: 559px) {
  #key .key-tit {
    font-size: 2.8rem;
  }
  #key .key-tit .key-r {
    width: 42px;
  }
}
#key .key-text {
  margin-top: 10px;
  font-size: 2.2rem;
  font-weight: 700;
  position: relative;
  z-index: 2;
}
#key .key-text .l {
  font-size: 3rem;
  color: #188A1C;
}
@media (max-width: 559px) {
  #key .key-text {
    margin-top: 30px;
    font-size: 2rem;
    line-height: 1.5;
  }
  #key .key-text .l {
    font-size: 2.5rem;
  }
}

.top-block {
  width: 1200px;
  margin: 0 auto;
  padding: 80px 0;
}
@media screen and (max-width: 1280px) {
  .top-block {
    width: 100%;
    padding: 80px 50px;
  }
}
@media (max-width: 559px) {
  .top-block {
    padding: 60px 30px;
  }
}

.top-section-tit {
  padding-bottom: 25px;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.3;
  position: relative;
}
.top-section-tit:after {
  content: "";
  width: 30px;
  height: 2px;
  border-radius: 1px;
  background: #188A1C;
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 959px) {
  .top-section-tit {
    text-align: center;
  }
  .top-section-tit:after {
    width: 60px;
    left: calc(50% - 30px);
  }
}
@media (max-width: 559px) {
  .top-section-tit {
    font-size: 2.8rem;
  }
  .top-section-tit:after {
    width: 40px;
    left: calc(50% - 20px);
  }
}

#top-about .top-block {
  padding-top: 100px;
}
#top-about .top-about-box {
  display: flex;
  margin-top: 60px;
}
@media (max-width: 959px) {
  #top-about .top-about-box {
    flex-direction: column;
  }
}
#top-about .top-about-box .top-about-left {
  width: 320px;
  flex-shrink: 0;
  position: relative;
}
#top-about .top-about-box .top-about-left:after {
  content: "";
  width: 100px;
  height: 1px;
  background: #E8EDD4;
  position: absolute;
  right: 0;
  top: 50px;
  transform: rotate(110deg);
}
@media (max-width: 959px) {
  #top-about .top-about-box .top-about-left:after {
    display: none;
  }
}
#top-about .top-about-box .top-about-right {
  padding-top: 20px;
}
@media (max-width: 959px) {
  #top-about .top-about-box .top-about-right {
    padding-top: 0;
  }
}
#top-about .top-about-en {
  font-family: "Roboto", sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
  color: #188A1C;
  letter-spacing: 0;
}
@media (max-width: 559px) {
  #top-about .top-about-en {
    font-size: 3.6rem;
  }
}
#top-about .top-about-subtit {
  margin-top: 20px;
  font-size: 2.2rem;
  line-height: 1.3;
}
@media (max-width: 559px) {
  #top-about .top-about-subtit {
    margin-top: 10px;
    font-size: 1.8rem;
  }
}
#top-about p {
  margin-top: 30px;
}
@media (max-width: 559px) {
  #top-about p {
    margin-top: 20px;
  }
}
#top-about .top-about-criteria {
  counter-reset: item;
}
#top-about .top-about-criteria li {
  padding-left: 35px;
  margin-top: 30px;
  position: relative;
}
#top-about .top-about-criteria li:before {
  counter-increment: item;
  content: counter(item) ".";
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  color: #188A1C;
  position: absolute;
  left: 0;
  top: 0;
}
#top-about .performance a {
  color: #396464;
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  #top-about .performance a:hover {
    text-decoration: none;
  }
}
@media (max-width: 959px) {
  #top-about .top-about-btn {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 559px) {
  #top-about .top-about-btn {
    margin-top: 30px;
  }
}

#top-seminar {
  background-color: #f8f6ed;
}
#top-seminar .top-seminar-subtit {
  padding-left: 10px;
  margin-top: 50px;
  font-size: 2rem;
  border-left: 4px solid #188A1C;
}

#top-column {
  background: url(../images/bg_top_column.jpg) center center no-repeat;
  background-size: cover;
}
#top-column .top-column-tit {
  color: #fff;
}
#top-column .top-column-tit:after {
  background: #fff;
}
#top-column .column-list {
  margin-top: 50px;
}
#top-column .btn {
  background: #fff;
  color: #396464;
}
#top-column .btn .icon {
  background: #396464;
}
#top-column .btn .icon svg {
  fill: #fff;
}
#top-column .btn:after {
  background: linear-gradient(90deg, rgba(57, 100, 100, 0.4), #fff);
}
@media (max-width: 559px) {
  #top-column .swiper {
    overflow: visible !important;
  }
  #top-column .swiper-wrapper {
    display: flex;
    gap: 0;
    margin-top: 30px;
  }
  #top-column .swiper-slide {
    width: 250px !important;
  }
  #top-column .column-list-image {
    height: 120px;
  }
  #top-column .swiper-scrollbar {
    bottom: -20px;
    background: rgba(255, 255, 255, 0.2);
  }
  #top-column .swiper-scrollbar-drag {
    background: #fff;
  }
  #top-column .btn-area {
    margin-top: 60px;
  }
}
@media (max-width: 959px) {
  #top-column .column-list li:nth-child(n+5) {
    display: none;
  }
}
@media (max-width: 559px) {
  #top-column .column-list li {
    display: block !important;
  }
}

#top-ifa {
  background: url(../images/bg_pattern.png) center center repeat;
  background-size: 250px;
  padding-bottom: 200px;
}
#top-ifa .top-ifa-box {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 959px) {
  #top-ifa .top-ifa-box {
    display: block;
    position: relative;
  }
}
#top-ifa .top-ifa-left {
  width: 730px;
}
@media (max-width: 959px) {
  #top-ifa .top-ifa-left {
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 559px) {
  #top-ifa .top-ifa-left {
    width: 100%;
  }
}
#top-ifa .top-ifa-right {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  width: 430px;
}
@media (max-width: 959px) {
  #top-ifa .top-ifa-right {
    justify-content: space-between;
    width: 140%;
    position: absolute;
    top: 100px;
    left: -20%;
  }
}
@media (max-width: 559px) {
  #top-ifa .top-ifa-right {
    gap: 20px;
    margin-top: 20px;
    width: 140%;
    position: relative;
    top: 0;
    left: -20%;
  }
}
#top-ifa p {
  margin-top: 30px;
}
@media (max-width: 559px) {
  #top-ifa p {
    margin-top: 20px;
  }
}
#top-ifa .top-ifa-image {
  border-radius: 15px;
  line-height: 1;
  overflow: hidden;
}
#top-ifa .top-ifa-image:nth-child(2) {
  margin-top: 150px;
}
@media (max-width: 959px) {
  #top-ifa .top-ifa-image {
    width: 18%;
  }
  #top-ifa .top-ifa-image img {
    width: 100%;
    height: auto;
  }
  #top-ifa .top-ifa-image:nth-child(2) {
    margin-top: calc(150px - 8vw);
  }
}
@media (max-width: 559px) {
  #top-ifa .top-ifa-image {
    width: 200px;
    height: 150px;
  }
  #top-ifa .top-ifa-image:nth-child(2) {
    margin-top: 20px;
  }
}
@media (max-width: 959px) {
  #top-ifa .top-ifa-btn {
    display: flex;
    justify-content: center;
  }
}

article {
  padding-bottom: 200px;
}

#aboutus table tbody th, #aboutus table tbody td {
  vertical-align: top;
}
#aboutus table tbody td {
  padding: 24px;
}
@media (max-width: 559px) {
  #aboutus table tbody td {
    padding: 10px;
  }
}
#aboutus table tbody th {
  background: none;
  padding: 18px 0;
  width: 140px;
}
@media (max-width: 559px) {
  #aboutus table tbody th {
    width: 100%;
    padding: 10px 0 0;
  }
}
#aboutus table .table-label {
  display: inline-block;
  width: 100%;
  padding: 8px 10px;
  font-size: 1.4rem;
  text-align: center;
  background: #E8EDD4;
  border-radius: 5px;
}
@media (max-width: 559px) {
  #aboutus table .table-label {
    width: auto;
    padding: 5px 10px;
  }
}

.column-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px 20px;
}
@media (max-width: 959px) {
  .column-list {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
}
.column-list a {
  display: block;
  height: 100%;
  padding: 20px;
  color: inherit;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 4px;
}
@media (hover: hover) and (pointer: fine) {
  .column-list a:hover {
    text-decoration: none;
  }
}
.column-list .column-list-image {
  width: 100%;
  height: 160px;
}
.column-list .column-list-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.column-list .column-list-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.column-list .column-list-date {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0;
}
.column-list .column-list-tag {
  display: inline-block;
  padding: 7px 10px;
  font-size: 1.3rem;
  line-height: 1;
  color: #fff;
  background: #396464;
}
.column-list .column-list-tag.seminar {
  background: #00A982;
}
.column-list .column-list-tag.investment {
  background: #253631;
}
@media (max-width: 559px) {
  .column-list .column-list-tag {
    padding: 4px 5px;
    font-size: 1.2rem;
  }
}
.column-list .column-list-tit {
  margin-top: 10px;
  line-height: 1.5;
}

#am.bn-index article, #am.bn-investment article, #am.bn-performance article {
  background: #f1f1f1;
}
#am article {
  position: relative;
  z-index: 1;
}
#am article:before {
  content: "Asset Management";
  font-family: "Roboto", sans-serif;
  font-size: 13rem;
  color: #396464;
  font-weight: 700;
  white-space: nowrap;
  position: absolute;
  left: 50px;
  top: 200px;
  opacity: 0.1;
  transform: rotate(90deg);
  transform-origin: left center;
}
@media (max-width: 959px) {
  #am article:before {
    font-size: 10rem;
    left: 30px;
  }
}
@media (max-width: 559px) {
  #am article:before {
    display: none;
  }
}
#am article .page-body {
  position: relative;
  z-index: 2;
}
#am .am-tit {
  font-size: 3.2rem;
}
@media (max-width: 559px) {
  #am .am-tit {
    font-size: 2.4rem;
    text-align: center;
  }
}
#am .column-list {
  margin-top: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
@media (max-width: 959px) {
  #am .column-list {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
}
@media (max-width: 559px) {
  #am .column-list {
    grid-template-columns: 1fr;
    gap: 30px;
    margin-top: 30px;
  }
}
#am .column-list-item a {
  text-decoration: none;
  color: inherit;
}
#am .column-detail-info .column-detail-date {
  margin-right: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0;
}
#am .column-detail-info .column-detail-tag {
  display: inline-block;
  padding: 7px 10px;
  font-size: 1.3rem;
  line-height: 1;
  color: #fff;
  background: #396464;
}
#am .column-detail-info .column-detail-tag.seminar {
  background: #00A982;
}
#am .column-detail-info .column-detail-tag.investment {
  background: #253631;
}
#am .column-detail-tit {
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 3.2rem;
}
@media (max-width: 559px) {
  #am .column-detail-tit {
    font-size: 2.4rem;
    margin-top: 10px;
  }
}
#am .page-navigation-list {
  display: block;
  width: 960px;
  margin: 0 auto 60px;
  padding-top: 70px;
  text-align: center;
  position: relative;
}
@media (max-width: 959px) {
  #am .page-navigation-list {
    width: 100%;
  }
}
@media (max-width: 559px) {
  #am .page-navigation-list {
    padding-top: 0;
  }
}
#am .page-navigation-list a {
  color: #396464;
}
#am .page-navigation-list .page-navigation-prev, #am .page-navigation-list .page-navigation-next {
  width: 440px;
  position: absolute;
  top: 0;
}
#am .page-navigation-list .page-navigation-prev a, #am .page-navigation-list .page-navigation-next a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  padding: 13px 20px;
  line-height: 1.5;
  background: #fff;
  border: 1px solid #396464;
  border-radius: 10px;
  transition: background 0.25s linear;
}
@media (hover: hover) and (pointer: fine) {
  #am .page-navigation-list .page-navigation-prev a:hover, #am .page-navigation-list .page-navigation-next a:hover {
    text-decoration: none;
    background: #E8EDD4;
  }
}
@media (max-width: 959px) {
  #am .page-navigation-list .page-navigation-prev, #am .page-navigation-list .page-navigation-next {
    width: 48%;
  }
  #am .page-navigation-list .page-navigation-prev a, #am .page-navigation-list .page-navigation-next a {
    padding: 13px;
  }
}
@media (max-width: 559px) {
  #am .page-navigation-list .page-navigation-prev, #am .page-navigation-list .page-navigation-next {
    width: 45px;
    top: -12px;
  }
  #am .page-navigation-list .page-navigation-prev a:before, #am .page-navigation-list .page-navigation-next a:before {
    content: "";
    width: 10px;
    height: 10px;
    border-top: 2px solid #396464;
    border-right: 2px solid #396464;
  }
}
#am .page-navigation-list .page-navigation-prev {
  left: 0;
}
@media (max-width: 959px) {
  #am .page-navigation-list .page-navigation-prev a {
    border-radius: 0 10px 10px 0;
    border-left: none;
  }
}
@media (max-width: 559px) {
  #am .page-navigation-list .page-navigation-prev a:before {
    transform: rotate(-135deg);
  }
}
#am .page-navigation-list .page-navigation-next {
  right: 0;
}
@media (max-width: 959px) {
  #am .page-navigation-list .page-navigation-next a {
    border-radius: 10px 0 0 10px;
    border-right: none;
  }
}
@media (max-width: 559px) {
  #am .page-navigation-list .page-navigation-next a:before {
    transform: rotate(45deg);
  }
}
#am .page-body figure {
  margin-top: 30px;
}
#am .page-body table {
  font-size: 1.5rem;
  letter-spacing: 0;
}
@media (max-width: 559px) {
  #am .page-body table {
    font-size: 1.3rem;
  }
}
#am .page-body table th, #am .page-body table td {
  border: 1px solid #ccc;
}
@media (max-width: 959px) {
  #am .column-table-wrap {
    overflow-x: scroll;
  }
  #am .column-table-wrap table {
    width: 960px;
  }
}
@media (max-width: 559px) {
  #am .column-table-wrap table {
    width: 840px;
  }
}
#am .am-search-area {
  margin-top: 60px;
  padding: 20px;
  background: #f8f6ed;
  border-radius: 10px;
}
#am .am-sub-tit {
  position: relative;
  padding-left: 20px;
}
#am .am-sub-tit:before {
  content: "";
  width: 10px;
  height: 2px;
  background-color: #396464;
  border-radius: 9999px;
  position: absolute;
  top: 0.6em;
  left: 0;
}
@media (max-width: 559px) {
  #am .am-sub-tit {
    font-size: 1.8rem;
  }
}
#am .head-search-form {
  margin-top: 20px;
}
@media (max-width: 559px) {
  #am .head-search-form {
    margin-top: 10px;
  }
}
#am .head-search-form form {
  display: flex;
  gap: 20px;
}
@media (max-width: 559px) {
  #am .head-search-form form {
    gap: 10px;
  }
}
@media (max-width: 559px) {
  #am .head-search-form input[type=text]::placeholder {
    font-size: 1.3rem;
  }
}
#am .search-btn {
  display: block;
  width: 80px;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  border: 1px solid #396464;
  border-radius: 10px;
  background: #396464;
  padding: 0;
  transition: all 0.25s linear;
}
@media (hover: hover) and (pointer: fine) {
  #am .search-btn:hover {
    text-decoration: none;
    background: #188A1C;
    color: #fff;
  }
}
@media (max-width: 559px) {
  #am .search-btn {
    width: 60px;
    font-size: 1.5rem;
  }
}
#am .search-btn:hover {
  cursor: pointer;
}

.seminar-tb {
  margin-top: 20px;
  font-size: 1.7rem;
}
@media (max-width: 559px) {
  .seminar-tb {
    width: 100vw !important;
    position: relative;
    left: -30px !important;
  }
}
.seminar-tb th, .seminar-tb td {
  border: none;
  padding: 12px 18px;
}
@media (max-width: 959px) {
  .seminar-tb th, .seminar-tb td {
    display: block;
    align-items: center;
    padding: 0 !important;
  }
}
.seminar-tb thead .th1, .seminar-tb thead .th2 {
  text-align: left;
}
.seminar-tb thead .th1 {
  width: 240px;
}
.seminar-tb thead .th3, .seminar-tb thead .th4 {
  width: 170px;
}
@media (max-width: 959px) {
  .seminar-tb thead tr {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
  }
  .seminar-tb thead th {
    padding: 8px 20px !important;
  }
  .seminar-tb thead .th1, .seminar-tb thead .th3 {
    display: none;
  }
  .seminar-tb thead .th4 {
    width: 160px;
    text-align: center;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }
}
@media (max-width: 559px) {
  .seminar-tb thead {
    display: none;
  }
}
.seminar-tb tbody tr {
  background: #fff;
}
.seminar-tb tbody tr:nth-child(2n) {
  background: #EFFDF3;
}
@media (max-width: 959px) {
  .seminar-tb tbody tr {
    display: grid;
    grid-template-columns: 1fr 160px;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 15px 0 15px 20px;
  }
}
@media (max-width: 559px) {
  .seminar-tb tbody tr {
    display: block;
    padding: 12px 30px;
  }
}
.seminar-tb tbody .seminar-tb-date {
  font-size: 1.6rem;
  letter-spacing: 0.05em;
}
.seminar-tb tbody .seminar-tb-date .date, .seminar-tb tbody .seminar-tb-date .time {
  display: block;
}
@media (max-width: 959px) {
  .seminar-tb tbody .seminar-tb-date {
    grid-column: 1;
    grid-row: 1;
  }
  .seminar-tb tbody .seminar-tb-date .date, .seminar-tb tbody .seminar-tb-date .time {
    display: inline-block;
  }
}
@media (max-width: 559px) {
  .seminar-tb tbody .seminar-tb-date {
    font-size: 1.4rem;
  }
}
.seminar-tb tbody .seminar-tb-tit a {
  color: #396464;
  font-weight: bold;
}
@media (max-width: 959px) {
  .seminar-tb tbody .seminar-tb-tit {
    grid-column: 1;
    grid-row: 2;
    font-size: 2rem;
    font-weight: 700;
    color: #151515;
  }
}
@media (max-width: 559px) {
  .seminar-tb tbody .seminar-tb-tit {
    font-size: 1.6rem;
  }
}
.seminar-tb tbody .seminar-tb-place {
  text-align: center;
}
@media (max-width: 959px) {
  .seminar-tb tbody .seminar-tb-place {
    grid-column: 1;
    grid-row: 3;
    margin-top: 5px;
    font-size: 1.3rem;
    text-align: left;
  }
  .seminar-tb tbody .seminar-tb-place:before {
    content: "会場：";
  }
}
.seminar-tb tbody .seminar-tb-entry {
  padding: 12px 0;
  text-align: center;
}
@media (max-width: 959px) {
  .seminar-tb tbody .seminar-tb-entry {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 2;
    grid-row: 1/4;
    padding: 0;
  }
}
.seminar-tb .btn {
  justify-content: center;
  width: 130px;
  height: 40px;
  padding: 0;
  margin: 0 auto;
  color: #188A1C;
  border-radius: 20px;
  border: 1px solid #188A1C;
  background: #fff;
  transition: all 0.25s linear;
}
@media (hover: hover) and (pointer: fine) {
  .seminar-tb .btn:hover {
    text-decoration: none;
    background: #188A1C;
    color: #fff;
  }
}
.seminar-tb .btn:after {
  display: none;
}
@media (max-width: 559px) {
  .seminar-tb.seminar-offline .seminar-tb-entry {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    text-align: left;
  }
  .seminar-tb.seminar-offline .seminar-tb-entry:before {
    content: "お申し込み：";
  }
}

#seminar article {
  background-color: #f8f6ed;
}
#seminar .seminar-tb {
  font-size: 1.6rem;
}
#seminar .seminar-tb .th1 {
  width: 185px;
}
#seminar .seminar-tb .seminar-tb-date {
  font-size: 1.5rem;
}
#seminar .seminar-outline {
  margin-top: 80px;
}
#seminar .seminar-teacher {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
}
@media (max-width: 559px) {
  #seminar .seminar-teacher {
    display: block;
  }
}
#seminar .seminar-teacher .seminar-teacher-image {
  flex-shrink: 0;
  width: 270px;
  margin-right: 30px;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 559px) {
  #seminar .seminar-teacher .seminar-teacher-image {
    width: 240px;
    height: 240px;
    margin: 0 auto;
  }
}
#seminar .seminar-teacher .seminar-teacher-name {
  font-size: 3rem;
}
@media (max-width: 559px) {
  #seminar .seminar-teacher .seminar-teacher-name {
    font-size: 2.1rem;
  }
}
#seminar .seminar-teacher .seminar-teacher-text p {
  margin-top: 15px;
}
@media (max-width: 559px) {
  #seminar .seminar-teacher .seminar-teacher-text {
    margin-top: 15px;
  }
}
#seminar .seminar-movie {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin: 30px auto 0;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
#seminar .seminar-movie iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#seminar .book-list {
  display: flex;
  justify-content: space-between;
  gap: 4%;
  align-items: center;
}
#seminar .book-list li {
  width: 21%;
}
#seminar .book-list li img {
  width: 100%;
}
#seminar .seminar-form-ifa {
  margin-top: 40px;
}
#seminar .anchor-box {
  display: flex;
  margin-top: 40px;
  background: #fff;
  border: 1px solid #396464;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 559px) {
  #seminar .anchor-box {
    display: block;
    position: relative;
    overflow: visible;
  }
}
#seminar .anchor-tit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  background: #E8EDD4;
}
@media (max-width: 559px) {
  #seminar .anchor-tit {
    width: 80px;
    height: 30px;
    font-size: 1.2rem;
    color: #fff;
    background: #396464;
    border-radius: 5px;
    position: absolute;
    left: 20px;
    top: -15px;
  }
}
#seminar .anchor-list {
  display: flex;
  gap: 30px;
  margin-top: 0;
  padding: 20px 30px;
}
@media (max-width: 959px) {
  #seminar .anchor-list {
    flex-wrap: wrap;
    gap: 10px 0;
    width: calc(100% - 100px);
  }
  #seminar .anchor-list li {
    width: 20%;
  }
}
@media (max-width: 559px) {
  #seminar .anchor-list {
    gap: 5px 0;
    width: 100%;
    padding: 20px 30px 15px;
  }
}
#seminar .ifa-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px 1%;
  margin-top: 20px;
}
@media (max-width: 959px) {
  #seminar .ifa-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 559px) {
  #seminar .ifa-list {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }
}
#seminar .ifa-list li {
  font-size: 1.8rem;
  position: relative;
}
@media (max-width: 559px) {
  #seminar .ifa-list li {
    font-size: 1.4rem;
    letter-spacing: 0;
  }
}
#seminar .ifa-list input[type=radio] {
  position: absolute;
  left: 10px;
  top: 18px;
}
#seminar .ifa-list input[type=radio]:checked + label {
  background-color: #E8EDD4;
}
@media (max-width: 559px) {
  #seminar .ifa-list input[type=radio] {
    opacity: 0;
  }
}
#seminar .ifa-list label {
  display: block;
  padding: 4px 0 4px 30px;
  background-color: #fff;
  border: 1px solid #396464;
  border-radius: 10px;
  transition: background 0.25s linear;
}
@media (hover: hover) and (pointer: fine) {
  #seminar .ifa-list label:hover {
    background-color: #E8EDD4;
  }
}
@media (max-width: 559px) {
  #seminar .ifa-list label {
    padding: 4px;
  }
}
#seminar .seminar-form-tb td#shimei input {
  width: 150px;
}
#seminar .seminar-form-tb td#shimei label {
  display: inline-block;
}
#seminar .seminar-form-tb td#shimei label + label {
  margin-left: 20px;
}
@media (max-width: 559px) {
  #seminar .seminar-form-tb td#shimei {
    display: flex;
    gap: 10px;
  }
  #seminar .seminar-form-tb td#shimei input {
    width: calc(100% - 2em);
  }
  #seminar .seminar-form-tb td#shimei label + label {
    margin-left: 0;
  }
}
#seminar .seminar-form-block {
  margin-top: 60px;
}
@media (max-width: 559px) {
  #seminar .seminar-form-block .seminar-form-tit {
    font-size: 1.8rem;
  }
}
#seminar .seminar-form-block .seminar-form-parts {
  margin-top: 30px;
}
#seminar .simulation-article {
  padding-bottom: 100px;
  position: relative;
}
@media (max-width: 959px) {
  #seminar .simulation-article {
    padding-bottom: 0;
  }
}
#seminar .simulation-article:before {
  content: "Simulation";
  font-family: "Roboto", sans-serif;
  font-size: 13rem;
  color: #396464;
  font-weight: 700;
  white-space: nowrap;
  position: absolute;
  left: 50px;
  top: 200px;
  z-index: 1;
  opacity: 0.1;
  transform: rotate(90deg);
  transform-origin: left center;
}
#seminar .simulation-article:after {
  content: "";
  width: 100%;
  height: 150px;
  background: linear-gradient(0deg, #fff 0%, #f8f6ed 100%);
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 959px) {
  #seminar .simulation-article:after {
    height: 80px;
  }
}
#seminar .simulation-article .page-body {
  position: relative;
  z-index: 2;
}
#seminar .simulation-setting {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 959px) {
  #seminar .simulation-setting {
    flex-direction: column;
    gap: 40px;
  }
}
#seminar .simulation-input {
  width: 55%;
}
@media (max-width: 959px) {
  #seminar .simulation-input {
    width: 100%;
  }
}
#seminar .simulation-input [name=init_val], #seminar .simulation-input [name=m_deposit] {
  width: 140px;
}
#seminar .simulation-input [name=m_withdrawl_rate] {
  width: 80px;
}
#seminar .simulation-input table {
  margin-top: 15px;
}
#seminar .simulation-input .btn-area {
  margin-top: 15px;
}
#seminar .simulation-input .btn-area .btn {
  width: 60%;
  border: none;
  justify-content: center;
}
#seminar .simulation-input-tit {
  font-size: 2.1rem;
}
#seminar .simulation-result {
  width: 40%;
  padding: 24px;
  background: #fff;
  border: 1px solid #396464;
  border-radius: 20px;
}
@media (max-width: 959px) {
  #seminar .simulation-result {
    width: 100%;
  }
}
#seminar .simulation-result p {
  margin-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #ccc;
}
#seminar .simulation-result p:last-child {
  border: none;
  padding-bottom: 0;
}
#seminar .simulation-result-tit {
  font-size: 2.1rem;
  margin-bottom: 20px;
}
#seminar .simulation-graph {
  margin-top: 80px;
  padding: 24px 10px;
  background: #fff;
}
@media (max-width: 959px) {
  #seminar .simulation-graph {
    height: 400px;
    margin-top: 30px;
  }
}
@media (max-width: 559px) {
  #seminar .simulation-graph {
    width: 100dvw;
    height: 300px;
    position: relative;
    left: -30px;
  }
}
#seminar .simulation-tb-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}
@media (max-width: 959px) {
  #seminar .simulation-tb-wrap {
    flex-direction: column;
    margin-top: 30px;
    gap: 30px;
  }
}
#seminar .simulation-tb-wrap .simulation-tb-accumulation, #seminar .simulation-tb-wrap .simulation-tb-withdrawal {
  width: 48%;
}
@media (max-width: 959px) {
  #seminar .simulation-tb-wrap .simulation-tb-accumulation, #seminar .simulation-tb-wrap .simulation-tb-withdrawal {
    width: 100%;
  }
}
#seminar .simulation-tb-wrap .simulation-tb-accumulation table, #seminar .simulation-tb-wrap .simulation-tb-withdrawal table {
  table-layout: fixed;
}
@media (max-width: 559px) {
  #seminar .simulation-tb-wrap .simulation-tb-accumulation table thead, #seminar .simulation-tb-wrap .simulation-tb-withdrawal table thead {
    font-size: 1.2rem;
  }
}
#seminar .simulation-tb-wrap .simulation-tb-accumulation table thead th:nth-child(1), #seminar .simulation-tb-wrap .simulation-tb-withdrawal table thead th:nth-child(1) {
  width: 80px !important;
}
@media (max-width: 559px) {
  #seminar .simulation-tb-wrap .simulation-tb-accumulation table thead th:nth-child(1), #seminar .simulation-tb-wrap .simulation-tb-withdrawal table thead th:nth-child(1) {
    width: 60px !important;
    padding: 5px;
  }
}
#seminar .simulation-tb-wrap .simulation-tb-accumulation table td, #seminar .simulation-tb-wrap .simulation-tb-withdrawal table td {
  text-align: right;
}
#seminar .simulation-tb-wrap .simulation-tb-accumulation table td:nth-child(1), #seminar .simulation-tb-wrap .simulation-tb-withdrawal table td:nth-child(1) {
  text-align: center;
}
@media (max-width: 559px) {
  #seminar .simulation-tb-wrap .simulation-tb-accumulation table td, #seminar .simulation-tb-wrap .simulation-tb-withdrawal table td {
    padding: 7px 7px 7px 0;
  }
}
#seminar .simulation-caution {
  font-size: 1.4rem;
}
#seminar .thanks-box {
  width: 800px;
  padding: 60px 0;
  margin: 0 auto;
  text-align: center;
  border-radius: 20px;
  background: #fff;
}
@media (max-width: 959px) {
  #seminar .thanks-box {
    width: 100%;
    padding: 60px 50px;
  }
  #seminar .thanks-box p {
    text-align: left;
  }
}
@media (max-width: 559px) {
  #seminar .thanks-box {
    padding: 30px;
  }
}

#ifa .anchor-box {
  display: flex;
  margin-top: 40px;
  border: 1px solid #396464;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 559px) {
  #ifa .anchor-box {
    display: block;
    position: relative;
    overflow: visible;
  }
}
#ifa .anchor-tit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  background: #E8EDD4;
}
@media (max-width: 559px) {
  #ifa .anchor-tit {
    width: 80px;
    height: 30px;
    font-size: 1.2rem;
    color: #fff;
    background: #396464;
    border-radius: 5px;
    position: absolute;
    left: 20px;
    top: -15px;
  }
}
#ifa .anchor-list {
  display: flex;
  gap: 30px;
  margin-top: 0;
  padding: 20px 30px;
}
@media (max-width: 959px) {
  #ifa .anchor-list {
    flex-wrap: wrap;
    gap: 10px 0;
    width: calc(100% - 100px);
  }
  #ifa .anchor-list li {
    width: 20%;
  }
}
@media (max-width: 559px) {
  #ifa .anchor-list {
    gap: 5px 0;
    width: 100%;
    padding: 20px 30px 15px;
  }
  #ifa .anchor-list li.anchor-ifa {
    width: 100%;
  }
}
#ifa [id^=anchor-] {
  margin-top: 60px;
  padding-bottom: 5px;
  border-bottom: 2px solid #188A1C;
}
@media (max-width: 559px) {
  #ifa [id^=anchor-] {
    margin-top: 40px;
  }
}
#ifa .ifa-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px 20px;
}
@media (max-width: 959px) {
  #ifa .ifa-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px 20px;
  }
}
@media (max-width: 559px) {
  #ifa .ifa-list {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 10px;
  }
}
#ifa .ifa-list-item a {
  display: flex;
  align-items: center;
  gap: 15px;
}
#ifa .ifa-list-image {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 10px;
}
#ifa .ifa-list-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#ifa .ifa-list-name {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.3;
}
@media (max-width: 559px) {
  #ifa .ifa-list-name {
    font-size: 1.4rem;
  }
}
#ifa .ifa-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 559px) {
  #ifa .ifa-detail {
    flex-direction: column;
    gap: 20px;
  }
}
#ifa .ifa-detail-image {
  width: 240px;
  height: 320px;
  border-radius: 20px;
  overflow: hidden;
}
#ifa .ifa-detail-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 559px) {
  #ifa .ifa-detail-image {
    height: 225px;
    height: 300px;
  }
}
#ifa .ifa-detail-text {
  width: calc(100% - 300px);
}
@media (max-width: 559px) {
  #ifa .ifa-detail-text {
    width: 100%;
  }
}
@media (max-width: 559px) {
  #ifa .ifa-detail-tit {
    text-align: center;
  }
}
#ifa .ifa-detail-tit .ifa-detail-name {
  font-size: 4rem;
}
@media (max-width: 559px) {
  #ifa .ifa-detail-tit .ifa-detail-name {
    font-size: 2.4rem;
  }
}
#ifa .ifa-detail-tit .ifa-detail-kana {
  display: block;
  padding-top: 5px;
  font-size: 1.6rem;
}
@media (max-width: 559px) {
  #ifa .ifa-detail-tit .ifa-detail-kana {
    font-size: 1.3rem;
  }
}
#ifa .ifa-detail-info dt {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 10px;
}
#ifa .ifa-detail-info dd {
  line-height: 1.5;
}
#ifa .ifa-detail-profile {
  margin-top: 60px;
}
@media (max-width: 559px) {
  #ifa .ifa-detail-profile {
    margin-top: 40px;
  }
}
#ifa .ifa-detail-stit {
  font-size: 2.4rem;
}
@media (max-width: 559px) {
  #ifa .ifa-detail-stit {
    font-size: 1.8rem;
  }
}

#contact article {
  padding-bottom: 0;
}
#contact .page-body iframe {
  width: 100%;
}
#contact .page-body .teQAzf {
  width: 960px;
}

#policy .page-body h2, #privacy .page-body h2, #terms .page-body h2 {
  font-size: 2.6rem;
}
#policy .page-body h3, #privacy .page-body h3, #terms .page-body h3 {
  font-size: 2.2rem;
}

#disclosure .page-tit-wrap .page-tit {
  white-space: nowrap;
  letter-spacing: 0.1em;
}
#disclosure .img-disclosure {
  border: 1px solid #ccc;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media print {
  body {
    zoom: 0.65;
  }
  #wrapper {
    min-width: 0;
  }
  .anm {
    opacity: 1;
    top: 0;
  }
}