//トップページ
@use "../settings/vars" as v;
@use "../settings/mixins" as m;

#key{
  height: 536px;
  background: url(../images/key_bg.png) top center repeat; background-size: 700px 536px;
  position: relative;
  @include m.for-tb(){
    height: 720px;
  }
  @include m.for-sp(){
    height: calc(100vh - 70px); max-height:720px; min-height:550px;
    background-size: 350px 268px;
  }
  .key-image{
    width:850px;
    position: absolute; right:-120px; bottom:-356px; z-index: 1;
    @media screen and (max-width:1280px) {
      right:-200px;
    }
    @include m.for-tb(){
      width:700px;
      right:50%; transform: translateX(50%); bottom:-294px;
    }
    @include m.for-sp(){
      width:380px;
      bottom:-159px;
    }
    
  }
  .key-text-box{
    width:1200px; height:100%; margin: 0 auto; padding-top: 140px;
    position: relative;
    @media screen and (max-width:1280px) {
      width:100%; padding:140px 50px;
    }
    @include m.for-tb(){
      padding-top: 60px;
      text-align: center;
    }
    @include m.for-sp(){
      display: flex; flex-direction: column; justify-content: center;
      padding:0 30px 200px;
      text-align: left;
    }
  }

  .key-tit{
    font-size: 4.5rem; line-height: 1.7;
    position: relative;z-index: 2;
    .key-r{
      display: inline-block;margin-right: 5px;
      img{
        display: block;
        position: relative;top:3px;
      }
    }
    @include m.for-tb(){
      display: inline-block;
    }
    @include m.for-sp(){
      font-size:2.8rem;
      .key-r{
        width:42px;
      }
    }
  }
  .key-text{
    margin-top: 10px;
    font-size:2.2rem; font-weight: 700;
    position: relative; z-index: 2;
    .l{
      font-size:3rem; color: v.$GREEN;
    }
    @include m.for-sp(){
      margin-top: 30px;
      font-size:2rem;line-height: 1.5;
      .l{
        font-size:2.5rem;
      }
    }
  }

}

.top-block{
  width:1200px; margin: 0 auto; padding: 80px 0;
  @media screen and (max-width:1280px) {
    width:100%; padding: 80px 50px;
  }
  @include m.for-sp(){
    padding:60px 30px;
  }
}
.top-section-tit{
  padding-bottom: 25px;
  font-size: 3.6rem; font-weight: 700; line-height: 1.3;
  position: relative;
  &:after{
    content:"";
    width:30px; height: 2px; 
    border-radius: 1px;
    background: v.$GREEN;
    position: absolute; left:0; bottom:0;
  }
  @include m.for-tb(){
    text-align: center;
    &:after{
      width:60px;
      left:calc(50% - 30px);
    }
  }
  @include m.for-sp(){
    font-size:2.8rem;
    &:after{
      width:40px;
      left:calc(50% - 20px);
    }
  }

}
#top-about{
  .top-block{
    padding-top: 100px;
  }

  .top-about-box{
    display: flex; margin-top: 60px;
    @include m.for-tb(){
      flex-direction: column;
    }
    
    .top-about-left{
      width:320px;
      flex-shrink: 0;
      position: relative;
      &:after{
        content:"";
        width:100px; height: 1px; background: v.$BGC_GREEN;
        position: absolute; right:0; top:50px; 
        transform: rotate(110deg);
        @include m.for-tb(){
          display: none;
        }
      }

    }
    .top-about-right{
      padding-top: 20px;
      @include m.for-tb(){
        padding-top: 0;
      }
    }
  }
  .top-about-en{
    font-family: v.$ROBOTO; font-size: 4rem; font-weight: 700; line-height: 1; color: v.$GREEN; letter-spacing: 0;
    @include m.for-sp(){
      font-size: 3.6rem;
    }
  }
  .top-about-subtit{
    margin-top: 20px;
    font-size: 2.2rem; line-height: 1.3;
    @include m.for-sp(){
      margin-top: 10px;
      font-size: 1.8rem;
    }
  }
  p{
    margin-top: 30px;
    @include m.for-sp(){
      margin-top: 20px;
    }
  }
  
  .top-about-criteria{
    counter-reset: item;
    li{
      padding-left:35px; margin-top: 30px;
      position: relative;
      &:before {
        counter-increment: item;
        content: counter(item)".";
        font-family: v.$ROBOTO; font-size: 3rem; font-weight: 700; line-height: 1; color: v.$GREEN;
        position: absolute; left:0;top:0;
      }
    }
  }
  .performance{
    a{
      color: v.$DGREEN; text-decoration: underline;
      @include m.hover{
        text-decoration: none;
      }
    }
  }
  .top-about-btn{
    @include m.for-tb(){
      display: flex; justify-content: center;
    }
    @include m.for-sp(){
      margin-top: 30px;
    }
  }
}

#top-seminar{
  background-color: v.$BGC_CREAM;
  .top-seminar-subtit{
    padding-left: 10px; margin-top: 50px;
    font-size: 2rem;
    border-left: 4px solid v.$GREEN;
  }
}


#top-column{
  background: url(../images/bg_top_column.jpg) center center no-repeat; background-size: cover;
  .top-column-tit{
    color: #fff;
    &:after{
      background: #fff;
    }
  }
  .column-list{
    margin-top: 50px;
  }
  .btn{
    background: #fff;
    color: v.$DGREEN;
    .icon{
      background: v.$DGREEN;
      svg{
        fill:#fff;
      }
    }
    &:after{
      background: linear-gradient(90deg,rgba(v.$DGREEN,0.4),#fff);
    }
  }
  @include m.for-sp(){
    .swiper{
      overflow: visible !important;
    }
    .swiper-wrapper{
      display: flex; gap: 0; margin-top: 30px;
    }
    .swiper-slide{
      width:250px !important;
    }
    .column-list-image{
        height:120px;
    }
    .swiper-scrollbar{
      bottom:-20px; background: rgba(#fff,0.2);
    }
    .swiper-scrollbar-drag{
      background: #fff;
    }
    .btn-area{
      margin-top: 60px;
    }
  }
  .column-list{
    li{
      @include m.for-tb(){
        &:nth-child(n+5){
          display: none;
        }
      }
      @include m.for-sp(){
        display: block !important;
      }
    }
  }
}

#top-ifa{
  background: url(../images/bg_pattern.png) center center repeat; background-size: 250px;
  padding-bottom: 200px;
  .top-ifa-box{
    display: flex; justify-content: space-between; gap:20px;
    @include m.for-tb(){
      display: block;
      position: relative;
    }
  }
  .top-ifa-left{
    width: 730px;
    @include m.for-tb(){
      width:80%; margin: 0 auto;
    }
    @include m.for-sp(){
      width: 100%;
    }
  }
  .top-ifa-right{
    display: flex; gap:30px; align-items: flex-start;
    width: 430px;
    @include m.for-tb(){
      justify-content: space-between;
      width:140%;
      position: absolute; top:100px; left:-20%;
    }
    @include m.for-sp(){
      gap: 20px; margin-top: 20px;
      width:140%;
      position: relative; top:0; left:-20%;
    }
  }
  p{
    margin-top: 30px;
    @include m.for-sp(){
      margin-top: 20px;
    }
  }
  .top-ifa-image{
    border-radius:15px;
    line-height: 1;
    overflow: hidden;
    &:nth-child(2){
      margin-top: 150px;
    }
    @include m.for-tb(){
      width: 18%;
      img{
        width: 100%; height: auto;
      }
      &:nth-child(2){
        margin-top: calc(150px - 8vw);
      }
    }
    @include m.for-sp(){
      width:200px; height:150px;
      &:nth-child(2){
        margin-top: 20px;
      }
    }
  }
  .top-ifa-btn{
    @include m.for-tb(){
      display: flex; justify-content: center;
    }
  }
}
