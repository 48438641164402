//mixins
@use "../settings/vars" as v;


@mixin last-mb0(){
	>:last-child{margin-bottom: 0;}
}

@mixin max($type){
	@if $type==w{
		width:100%; height:auto; 
	}@else if $type==h{
		width:auto; height:100%; 
	}
}

@mixin for-sp() {
  @media (max-width: v.$BREAK_SP ) {
	  @content;
  }
}
@mixin for-tb() {
  @media (max-width: v.$BREAK_TAB ) {
	  @content;
  }
}
@mixin hover {
  //hoverはPCのみ
  @media (hover: hover) and (pointer: fine)  {
    &:hover {
      @content;
    }
  }
}