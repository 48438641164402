//layout
@use "../settings/vars" as v;
@use "../settings/mixins" as m;


// ラッパー
#wrapper{
	width: 100%;min-width: 1000px;
	overflow-x: hidden;
}

@include m.for-tb(){
	#wrapper{
		min-width: 0;
	}

}

// ヘッダー
header{
	display: flex; justify-content: space-between; align-items: center;
	width: 100%; height: 90px; padding: 0 30px;
	background: rgba(#fff,0.85);
	position: fixed; top:0; left: 0; z-index: 100;
	@media screen and (max-width:1280px) {
		padding:0 10px 0 15px;
	}
	@include m.for-sp(){
		height:70px;
	}
	.logo{
		width:150px;
		img{
			width:100%; height: auto;
		}
		@include m.for-sp(){
			width:120px;
		}
	}
}

// グローバルナビ
#menu-btn{
	display: none;
}

.navi-wrap{

}
.gnavi{
	display: flex; align-items: center;
	font-weight: 700;
	.gnavi-list{
		display: flex; gap:2em;
		@media screen and (max-width:1280px) {
			font-size:1.5rem;
		}
		> li{
			position: relative;
			@include m.hover{
				>a:before{
					transform: scaleX(1); transform-origin: left center;
				}
				.gnavi-drop{
					opacity: 1; visibility: visible;
				}
			}
			
		}
		a{
			display: inline-block;
			padding:12px 0;
			color: v.$DGREEN; line-height: 1;
			position: relative;
			&:before{
				content:"";
				width:100%; height: 2px; border-radius:1px;
				background: v.$DGREEN;
				position: absolute; left:0; bottom:0;
				transform: scaleX(0); transform-origin: right center;
				transition: transform 0.25s v.$ease2;
			}
			@include m.hover{
				text-decoration: none;
				
			}
		}
		.active > a{
			&:before{
				transform: scaleX(1);
			}
		}
		@media screen and (max-width:1280px) {
			//.home{display: none;}
		}
	}
	.gnavi-drop{
		opacity: 0; visibility: hidden;
		width:260px; padding: 10px 20px;
		font-size: 1.4rem;
		position: absolute;	left:calc(50% - 125px);top:40px;
		border-radius: 10px; border:  1px solid v.$DGREEN;
		background: #fff;
		transition: all 0.2s linear;
		
		a{
			display: block;
			padding:10px 0;
			&:before{
				height: 1px;
			}
			@include m.hover{
				&:before{
					transform: scaleX(1); transform-origin: left center;
				}
			}
		}
	}
}

.head-contact{
	margin-left: 40px;
	.btn-contact{
		font-size: 1.6rem;
	}
	@media screen and (max-width:1280px) {
		margin-left: 30px;
		.btn-contact{
			font-size: 1.5rem;
		}
	}
	@media screen and (max-width:1100px) {
		margin-left: 20px;
		.btn-contact{
			height:52px; padding: 0 20px;
			font-size:1.4rem; letter-spacing: 0.05em;
			.icon{display: none;}
		}
	}
}

@include m.for-tb(){
	#menu-btn{
		display: block;width: 60px;height: 60px;
		position: fixed;right:20px;top:15px;
		cursor: pointer;z-index: 999;overflow: hidden;
		.menu-line{
			display: block;width: 34px;height: 2px;border-radius: 1px;
			background: v.$DGREEN;position: absolute;left:calc(50% - 17px);
			&:nth-child(1){top:19px;transition: top 0.1s v.$ease2 0.2s,transform 0.2s v.$ease2 0.05s;}
			&:nth-child(2){top:29px;transition: transform 0.1s v.$ease2 0s;}
			&:nth-child(3){top:39px;transition: top 0.1s v.$ease2 0.2s,transform 0.2s v.$ease2 0.05s;}
		}
	}
	
	#menu.open{
		#menu-btn{
			.menu-line{
				background: #fff;
				&:nth-child(1){top:29px;transform: rotate(-135deg);transition: top 0.1s v.$ease2 0.05s,transform 0.2s v.$ease2 0.1s;}
				&:nth-child(2){transform: scaleX(0);}
				&:nth-child(3){top:29px;transform: rotate(135deg);transition: top 0.1s v.$ease2 0.05s,transform 0.2s v.$ease2 0.1s;}
			}
		}
		.navi-wrap{visibility: visible;opacity: 1;}
	}
	.navi-wrap{
		display: flex; align-items: center; justify-content: center;
		visibility: hidden;opacity: 0;transition: all 0.5s v.$ease5;
		width: 100%;height: 100%;background: rgba(v.$DGREEN,0.95);backdrop-filter:blur(3px);
		overflow: auto;	-webkit-overflow-scrolling:touch;
		position: fixed;z-index: 998;top:0;left:0;
	}

	.gnavi{
		flex-direction: column; justify-content: center; align-items: center; gap:60px;
		.gnavi-list{
			flex-direction: column; justify-content: center; align-items: flex-start; gap:20px;
			font-size: 3.2rem; line-height: 1;
			a{
				color: #fff;
				&:before{
					width:200px;height:2px;
					background: rgba(#fff,0.8);
					left:-220px; top:calc(50% - 1px);
				}
				@include m.hover{
					&:before{
						display: none;
					}
				}
			}

			.home{
				display: block;
			}
		}
		.gnavi-drop{
			opacity: 1; visibility: unset;
			width: 100%;
			font-size:2.2rem;
			background: none;border: none;
			position: static;
			a{
				&:before{
					display: none;
				}
			}
		}
		
	}
	.head-contact{
		margin-left: 0;
		.btn-contact{
			width:400px; height: 100px; padding: 0 50px;
			font-size:2.8rem;
			border: 1px solid #fff; border-radius: 50px;
			.icon{
				display: flex;
				width:60px;height:60px;
				svg{
					transform: scale(1.5);
				}
			}
		}
	}
}

@include m.for-sp(){
	#menu-btn{
		width:44px; height: 44px;
		top:13px; right:15px;
		.menu-line{
			width:30px; left:calc(50% - 15px);
			&:nth-child(1){top:13px;}
			&:nth-child(2){top:21px;}
			&:nth-child(3){top:29px;}
		}
	}
	#menu.open{
		#menu-btn{
			.menu-line{
				&:nth-child(1),&:nth-child(3){top:21px;}
			}
		}
	}
	.gnavi{
		gap:30px;
		.gnavi-list{
			gap:0px;
			font-size: 1.8rem; 
		}
		a{
			&:before{
				height: 1px;
			}
		}
		.gnavi-drop{
			padding:0 20px;
			font-size:1.4rem;
			a{
				padding: 5px;
			}
		}
	}
	.head-contact{
		.btn-contact{
			width:260px; height:70px; padding:0 30px;
			font-size:1.8rem;
			.icon{
				width:40px; height: 40px;
				svg{
					transform: scale(1);
				}
			}
		}
	}
}



main{
	padding-top: 90px;
	min-height: 1000px;
	@include m.for-sp(){
		padding-top: 70px;
	}
}


.cta{
  display:flex; justify-content:center; align-items:center;
  width:1320px; height: 520px; margin: -200px auto 0;
  background: url(../images/bg_cta.jpg) center center no-repeat; background-size: cover;
  border-radius: 20px;
  position: relative; z-index: 2;
  @media screen and (max-width:1360px) {
    width:94%;
  }
  @include m.for-sp(){
    height: auto; padding: 40px 0;
  }
  .cta-box{
    text-align: center; color: #fff;
    @include m.for-sp(){
      width:100%; padding:0 30px;
    }
  }
  .cta-tit{
    font-size: 4rem; color: #fff;
    @include m.for-sp(){
      font-size:2.8rem;
    }
  }
  .cta-lead{
    margin-top: 30px;
    font-weight: 700; line-height: 1.75;
    @include m.for-sp(){
      margin-top: 20px;
      text-align: left; 
    }
  }
  .cta-btn-area{
    display: flex; justify-content: space-between; align-items: center; gap:30px;
    margin-top: 40px;
    @include m.for-tb(){
      flex-direction: column-reverse; gap:20px; margin-top: 20px;
    }
  }
  .cta-tel,.cta-form{
    display: flex; justify-content: center; align-items: center;
    width:400px; height: 180px;
    border-radius: 20px;
    @include m.for-tb(){
      height: 120px;
    }
    @include m.for-sp(){
      width:100%; height: 80px;
    }
  }
  .cta-tel{
    flex-direction: column;
    background: #fff;
    .cta-tel-number{
      display: inline-block;
      margin-top: 10px; padding-left: 40px;
      font-size: 3.4rem; font-family: v.$ROBOTO; font-weight: 700; color: v.$DGREEN; letter-spacing: 0; line-height: 1;
      background: url(../images/icon_tel.png) left center no-repeat; background-size: 33px;
      @include m.for-sp(){
        font-size:2.8rem; height:33px; line-height: 33px;
      }
    }
    .cta-tel-info{
      display: block;
      margin-top: 4px;
      font-size:1.2rem; letter-spacing: 0.05em;
      color: #333;
    }
  }
  .tel-wrap{
    display: block;
    width:100%;
  }
  .cta-form{
    background: #F7F5C9;
    font-size: 2.4rem; color: v.$DGREEN;
    @include m.for-sp(){
      font-size:2rem;
    }
    &:after{
      width:110%;
      left:-120%;
      transform: skewX(-15deg);
      transition: all v.$HOVER;
    }
    .label{
      transition: color v.$HOVER 0.05s;
    }
    .icon{
      width: 43px; height: 43px;
      background: v.$DGREEN;
      transition: background v.$HOVER 0.1s;
      svg{
        fill:#F7F5C9;
        transition: fill v.$HOVER 0.1s;
      }
    }
    @include m.hover{
      text-decoration: none;
      &:after{
        left:-5%;
        transform: skewX(0deg);
      }
      .label{
        color: #fff;
      }
      .icon{
        background: #D7E0E0;
        svg{
          fill:v.$DGREEN;
        }
      }
    }
  }
  
}


// フッター
footer{
	padding-bottom: 20px; margin-top: 150px;
	background: linear-gradient(180deg,#234720,#183B15);
	position: relative;
	@include m.for-tb(){
		margin-top: 100px;
	}
	@include m.for-sp(){
		margin-top: 70px;
	}
	&:before{
		content:"";
		width:100%;height:50px;
		background: linear-gradient(180deg,rgba(#fff,0),#FFFDD2);
		position: absolute; left:0;top:-50px; z-index:1;
	}
	.foot-top{
		width:1440px; height: 138px;
		background: url("../images/footer_top.png") top center no-repeat; background-size: cover;
		position: absolute; left:calc(50% - 720px); top:-99px; z-index: 2;
		@include m.for-tb(){
			width:768px; height: 100px; top:-71px; left:50%; transform: translateX(-50%);
		}
		@include m.for-sp(){
			width: 400px; height:56px; top:-40px;
		}
	}
	.foot-box{
		width: 960px; margin: 0 auto; padding-top: 80px;
		@include m.for-tb(){
			width: 100%; padding-top: 50px;
		}
	}
	.foot-middle{
		display: flex; justify-content: space-between;
		padding:0 20px 30px; 
		border-bottom: 1px solid #627A5F;
		@include m.for-tb(){
			padding:0 50px 30px;  gap:20px;
		}
		@include m.for-sp(){
			display: block;
			padding:0 30px 20px;
		}
	}
	.foot-company{
		
		.foot-company-name{
			font-size: 1.6rem; line-height: 1; font-weight: 700;color: #fff;
		}
		.foot-company-address{
			margin-top: 10px;
			font-size:1.3rem; line-height: 1.5;color: #fff;
			@include m.for-sp(){
				a{color: #fff;}
			}
		}
	}
	.foot-navi{
		@include m.for-sp(){
			margin-top: 20px;
		}
		.foot-navi-list{
			display: flex; flex-wrap: wrap;
			width: 320px;
			font-size:1.4rem;
			@include m.for-tb(){
				width: 280px;	font-size: 1.3rem;
			}
			li{
				&:nth-child(2n+1){
					width:60%;
				}
				&:nth-child(2n){
					width:40%;
				}
				@include m.for-tb(){
					&:nth-child(2n+1){
						width:50%;
					}
					&:nth-child(2n){
						width:50%;
					}
				}
				
			}
			a{
				display: inline-block;
				color: #fff;
				position: relative;
				&:before{
					content:"";
					width:100%; height:1px;
					background: #fff;
					position: absolute; left:0; bottom:3px;
					transform: scaleX(0); transform-origin: right center;
					transition: transform 0.25s v.$ease2;
				}
				@include m.hover{
					text-decoration: none;
					&:before{
						transform: scaleX(1); transform-origin: left center;
					}
				}
			}
		}
	}
	.foot-snavi{
		margin-top: 30px;
		@include m.for-tb(){
			padding:0 50px;
		}
		@include m.for-sp(){
			margin-top: 20px;
			padding:0 30px;
		}
		.foot-snavi-list{
			display: flex; justify-content: center; gap:2.5em;
			font-size: 1.3rem;
			@include m.for-tb(){
				font-size:1.2rem; gap:5px 2em; flex-wrap: wrap;
			}
			@include m.for-sp(){
				flex-wrap: wrap; 
			}
			a{
				color: #D1D8D1;
			}
		}
	}
	.pagetop{
		display: none;
	}
	.copyright{
		display: block;
		margin-top: 50px;
		font-size: 1.2rem; color: #D1D8D1; text-align: center;
		@include m.for-tb(){
			margin-top: 30px;
		}
		@include m.for-sp(){
			font-size:1rem;
		}
	}
}
